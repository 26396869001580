import React, { useEffect, useState } from 'react';
import './style.css';

import { Container, Row, Col } from 'reactstrap';
import api from '../../services/api';

const Termos = () => {
    const [term, setTerm] = useState({});

    useEffect(() => {
        api.get('term')
            .then(response => setTerm(response.data));
    }, []);

    return (
        <>
            <div className="backgroundPage pagesTerms">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <div className="namePage">
                                <h1>TERMOS E CONDIÇÕES DE USO</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="sectionTerms">
                <Container>
                    <Row>
                        <Col sm={12}>
                            {term?.description}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Termos;