import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import api from "../../services/api";
import { setCustomer, validatedToken } from "../../services/auth";
import { useHistory } from "react-router";
import { toast } from 'react-toastify';
import { maskCpf } from "../../services/helpers";

const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [title] = useState("Registre-se");
    const [textButton, setTextButton] = useState('Registrar');

    const history = useHistory();
    useEffect(() => {
        const validateCustomer = async () => {
            const isSign = await validatedToken();
            if (isSign) {
                history.push('/checkout/entrega');
            }
        }
        validateCustomer();
    }, []);

    const handleChange = e => {
        if (e.target.name === 'firstName') {
            setFirstName(e.target.value);
        }
        if (e.target.name === 'lastName') {
            setLastName(e.target.value);
        }
        if (e.target.name === 'cpf') {
            setCpf(maskCpf(e.target.value));
        }
        if (e.target.name === 'email') {
            setEmail(e.target.value);
        }
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
        if (e.target.name === 'password_confirmation') {
            setPasswordConfirmation(e.target.value);
        }
    }

    const handleLogin = () => history.push('/login');

    const validate = () => {
        if (firstName === '') {
            toast.error("Informe seu nome.");
            return false;
        }
        if (lastName === '') {
            toast.error("Informe seu sobrenome.");
            return false;
        }
        if (email === '') {
            toast.error("Informe seu email.");
            return false;
        }
        if (cpf === '') {
            toast.error("Informe seu CPF.");
            return false;
        }
        if (password === '') {
            toast.error("Informe sua senha.");
            return false;
        }
        if (password.length < 8) {
            toast.error("A senha deve ter no mínimo 8 caracteres.");
            return false;
        }
        if (password_confirmation === '') {
            toast.error("Informe a confirmação de senha.");
            return false;
        }
        if (password_confirmation !== password) {
            toast.error("A confirmação de senha deve ser igual a senha digitada.");
            return false;
        }
        return true;
    }
    const next = async () => {
        const valid = validate();
        if (valid) {
            setTextButton('Carregando...');
            try {
                const response = await api.post(`customer/register`, {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    cpf,
                    password,
                    password_confirmation,
                });
                const { customer, token } = response.data.success;
                setCustomer(customer, token);

                setTextButton('Registrar');
                history.push('/checkout/entrega');
            } catch (error) {
                if (error.response?.status === 422) {
                    const { errors } = error.response.data;
                    toast.error("Este e-mail já esta sendo utilizado.")
                    console.log(typeof errors);
                }
                setTextButton('Registrar');
                console.log(error.response.status);
            }
        }
    }


    return (
        <SideModalBaseCheckout
            header={true}
            title={title}
            actual='register'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="questionOrder center">
                            <span>Informe seus dados de acesso</span>
                        </div>
                        <Form>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup className="checkoutRegister">
                                        <Label htmlFor="firstName">Nome</Label>
                                        <Input type="firstName" value={firstName} onChange={handleChange} name="firstName" id="firstName" placeholder="Ex: Joao" />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup className="checkoutRegister">
                                        <Label htmlFor="lastName">Sobrenome</Label>
                                        <Input type="lastName" value={lastName} onChange={handleChange} name="lastName" id="lastName" placeholder="Ex: Alves" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup className="checkoutRegister">
                                        <Label htmlFor="email">Seu e-mail</Label>
                                        <Input type="email" value={email} onChange={handleChange} name="email" id="email" placeholder="Ex: joaoalves@email.com.br" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup className="checkoutRegister">
                                        <Label htmlFor="cpf">CPF</Label>
                                        <Input type="cpf" value={cpf} onChange={handleChange} name="cpf" id="cpf" placeholder="Ex: 000.000.000-00" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>

                                    <FormGroup className="checkoutRegister">
                                        <Label htmlFor="password">Sua senha</Label>
                                        <Input type="password" value={password} onChange={handleChange} name="password" id="password" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>

                                    <FormGroup className="checkoutRegister">
                                        <Label htmlFor="password_confirmation">Confirmação de senha</Label>
                                        <Input type="password" value={password_confirmation} onChange={handleChange} name="password_confirmation" id="password_confirmation" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={6} >
                        <button className="button-link" onClick={handleLogin}>Já sou cadastrado</button>
                    </Col>
                </Row>
                <button className="btnOrange" onClick={next} disabled={textButton === "Registrar" ? false : true} >
                    {textButton}
                    <img src={bigArrow} alt="Icon" />
                </button>
                {/* <button className="button-link" id="finishLater" >finalizar mais tarde</button> */}
            </Container>
        </SideModalBaseCheckout>
    );
}
export default Register;
