import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";

const OrderGift = () => {
    const { updateShowOrderGift, updateShowBox, updateIsGift, updateIsGoing, updateGiftMessage, itemToEdit } = useHeader();
    const [isGift, setIsGift] = useState('none');
    const [giftMessage, setGiftMessage] = useState('');
    const [isGoing, setIsGoing] = useState(false);

    const [isFind] = useState(false);


    useEffect(() => {
        if (itemToEdit !== null) {
            setIsGift(itemToEdit.isGift);
            setIsGoing(itemToEdit.isGoing);
            setGiftMessage(itemToEdit.giftMessage);
        }
    }, []);

    const handleGift = () => {
        if (isGift === 'block' && giftMessage === '' && !isGoing) {
            alert('Informe a mensagem');
        } else {
            updateIsGift(isGift);
            updateIsGoing(isGoing);
            updateGiftMessage(giftMessage);
            updateShowOrderGift(false);
            updateShowBox(true);
        }
    }
    const handleChange = (e) => setGiftMessage(e.target.value);

    const handleInputChange = (e) => {
        setIsGoing(!isGoing);
        setGiftMessage(isGoing ? giftMessage : '');
    }

    return (
        <SideModalBase
            header={true}
            title='Pedido'
            edit={itemToEdit}
            actual='order_gift'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Estamos quase lá...</p>
                            <span>A caixa será para presente?</span>
                        </div>
                        <button className={`btnWhite ${isGift === 'block' ? 'active' : ''}`} onClick={() => setIsGift('block')}>
                            Sim
                        </button>
                        <button className={`btnWhite ${isGift === 'none' ? 'active' : ''}`} onClick={() => setIsGift('none')}>
                            Não
                        </button>
                        <p className="textAlert" >
                            Para a caixinha de presente, será adicionado R$5 ao seu pedido. É incluso uma fita com laço e um cartão para escrever sua mensagem.
                        </p>
                    </Col>
                </Row>
                <Row style={{ display: isGift }}>
                    <Col sm={12}>
                        <Form id="formGift">
                            <FormGroup>
                                <Label for="giftMessage">Qual mensagem você gostaria no cartão?</Label>
                                <Input type="textarea" name="giftMessage" value={giftMessage} onChange={handleChange} readOnly={isGoing} id="giftMessage" />
                            </FormGroup>
                            <FormGroup>
                                <span className="giftCheckbox" onClick={handleInputChange}>
                                    <input type="checkbox"
                                        checked={isGoing}
                                        onChange={handleInputChange} />
                                    <span></span>Eu vou escrever quando chegar
                                    </span>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <button className="btnOrange" onClick={handleGift} disabled={isFind} >
                    Continuar
                        <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}
export default OrderGift