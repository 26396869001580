import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';

import InputMask from 'react-input-mask';

import bigArrow from '../../images/big-arrow.png';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";
import api from "../../services/api";

const OrderCep = () => {

    const [zipCode, setZipCode] = useState('');
    const [zipSet, setZipSet] = useState('');
    const [displayHas, setDisplayHas] = useState('none');
    const [displayNotHas, setDisplayNotHas] = useState('none');
    const [raio, setRaio] = useState('');
    const [price, setPrice] = useState('0,00');
    const [isFind, setIsFind] = useState(true);

    const {
        cityData,
        cep,
        updateShowOrderCep,
        updateShowOrderDate,
        updateCep,
        updateZipSet
    } = useHeader();

    useEffect(() => {
        setZipCode(cep);
        validateZipCode(cep);
    }, []);

    const handleChange = e => {
        let value = e.target.value;
        setZipCode(value);
        validateZipCode(value);
    }

    const validateZipCode = (value) => {
        if (value.indexOf("_") === -1 && value.length > 0) {
            getCityZipCode(value);
        } else {
            setDisplayNotHas('none');
            setDisplayHas('none');
            setIsFind(true);
        }
    }

    const getCityZipCode = async (cep) => {
        const response = await api.get(`city/${cityData.id}/zip_set`, {
            params: { cep }
        });
        if (response.data.zip_set === null) {
            setDisplayNotHas('block');
            setDisplayHas('none');
            setRaio(cityData.url);
            setIsFind(true);
        } else {
            setDisplayNotHas('none');
            setDisplayHas('block');
            setIsFind(false);
            setPrice(response.data.zip_set.price);
            setZipSet(response.data.zip_set);
        }
    }
    const next = () => {
        updateZipSet(zipSet);
        updateCep(zipCode);
        updateShowOrderCep(false);
        updateShowOrderDate(true);
    }

    return (
        <SideModalBase
            header={true}
            title='Pedido'
            actual='order_cep'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Onde será a entrega?</p>
                            <span>Informe seu CEP</span>
                        </div>
                        <Form>
                            <FormGroup>
                                <InputMask mask="99999-999" value={zipCode} type="text" name="zipCode" id="orderCep" onChange={handleChange}>
                                    {(inputProps) => <Input {...inputProps} />}
                                </InputMask>
                            </FormGroup>
                        </Form>
                        <p className="textAlert" id="alertSuccess" style={{ display: displayHas }}>
                            Maravilha, atendemos a sua região.
                            Iremos entregar no horário que você escolher e avisaremos quando o pedido estiver à caminho.
                            Seu frete custará R$ <span id="price">{price}</span>.
                            </p>
                        <p className="textAlert" id="alertFail" style={{ display: displayNotHas }}>
                            Infelizmente não atendemos o seu cep :(<br />
                                Abaixo temos o raio de entrega para a sua região
                            </p>
                    </Col>
                </Row>
                <Row >
                    <Col sm={12}>
                        <img id="raioEntrega" style={{ display: displayNotHas }} src={raio} alt="Raio de entrega" />
                    </Col>
                </Row>
                <button className="btnOrange" onClick={next} disabled={isFind}>
                    Continuar
                        <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}

export default OrderCep;