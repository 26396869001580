import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import api from "../../services/api";
import { setCustomer } from "../../services/auth";
import { useHistory } from "react-router";
import { validatedToken } from '../../services/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [title] = useState("Acesse sua conta");
    const [textButton, setTextButton] = useState("Continuar");


    const history = useHistory();

    useEffect(() => {
        const validateCustomer = async () => {
            const isSign = await validatedToken();
            if (isSign) {
                history.push('/checkout/entrega');
            } else {
                history.push('/login');
            }
        }
        validateCustomer();
    }, []);


    const handleChange = (e) => {
        if (e.target.name === 'email') {
            setEmail(e.target.value);
        }
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
    }
    const handleRegister = () => {
        history.push('register');
    }


    const next = (e) => {
        const valid = validate();
        if (valid) {
            setTextButton('Carregando...');
            api.post('/customer/login', {
                email: email,
                password: password
            })
                .then(response => {
                    const { customer, success, token } = response.data;
                    if (success) {
                        setCustomer(customer, token);
                        history.push('checkout/entrega');
                    }
                    setTextButton('Continuar');
                })
                .catch(error => {
                    const { status, data } = error.response;
                    if (status === 401) {
                        toast.error(data.message.email[0]);
                    }
                    setTextButton('Continuar');
                });
        }
    }
    const validate = () => {
        if (email === '') {
            toast.error("Informe seu email.");
            return false;
        }
        if (password === '') {
            toast.error("Informe sua senha");
            return false;
        }
        return true;
    }
    return (
        <SideModalBaseCheckout
            header={true}
            title={title}
            actual='login'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="questionOrder center">
                            <span>Informe seus dados de acesso</span>
                        </div>
                        <Form>
                            <FormGroup className="checkoutLogin">
                                <Label htmlFor="email">Seu e-mail</Label>
                                <Input type="email" value={email} onChange={handleChange} name="email" id="email" placeholder="Ex: joaoalves@email.com.br" />
                            </FormGroup>
                            <FormGroup className="checkoutLogin">
                                <Label htmlFor="password">Sua senha</Label>
                                <Input type="password" value={password} onChange={handleChange} name="password" id="password" />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={6} >
                        <button className="button-link" onClick={handleRegister}>Não sou cadastrado</button>
                    </Col>
                    <Col sm={6} xs={6} >
                        <button className="button-link" id="forgotPassword" onClick={() => window.location.href='/recuperacao-de-senha'}>Esqueci a senha</button>
                    </Col>
                </Row>
                <button className="btnOrange" onClick={next} disabled={textButton === "Continuar" ? false : true}>
                    {textButton}
                    <img src={bigArrow} alt="Icon" />
                </button>
                {/* <button className="button-link" id="finishLater" href="#">finalizar mais tarde</button> */}
            </Container>
        </SideModalBaseCheckout>
    );

}
export default Login;