import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";


const OrderName = () => {

    const { updateShowOrderName, updateShowOrderGift, updateName, itemToEdit } = useHeader();

    const [isFind, setIsFind] = useState(true);
    const [name, setName] = useState('');

    const handleName = () => {
        updateName(name);
        updateShowOrderName(false);
        updateShowOrderGift(true);
    }

    const handleChange = e => {
        setName(e.target.value);
        if (e.target.value.length > 0) {
            setIsFind(false);
        } else {
            setIsFind(true);
        }
    }

    useEffect(() => {
        if (itemToEdit !== null) {
            setName(itemToEdit.name);
            setIsFind(false);
        }
    }, []);
    return (
        <SideModalBase
            header={true}
            title='Pedido'
            edit={itemToEdit}
            actual='order_name'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Escolha o nome da sua caixinha</p>
                            <span>Informe o nome</span>
                        </div>
                        <Form>
                            <FormGroup>
                                <Input type="text" name="name" value={name} onChange={handleChange} id="orderName" placeholder="Ex: Caixa do Pedro" />
                            </FormGroup>
                        </Form>
                        <p className="textAlert">
                            O nome estará na etiqueta ao lado da caixinha, para facilitar a identificação caso você crie mais de uma caixinha.
                        </p>
                    </Col>
                </Row>
                <button className="btnOrange" onClick={handleName} disabled={isFind}>
                    Continuar
                    <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}
export default OrderName;