import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { ToastContainer } from 'react-toastify';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Cardapio from './pages/Cardapio';
import Novidades from './pages/Novidades';
import Pedidos from './pages/Pedidos';
import Politica from './pages/Politica';
import Termos from './pages/Termos';
import QuemSomos from './pages/QuemSomos';
import Contact from './pages/Contact';
import Login from './pages/Login';
import RecoveryPassword from './pages/RecoveryPassword';
import Register from './pages/Register';
import Delivery from './pages/Delivery';
import Payment from './pages/Payment';
import Paypal from './pages/PayPal';
import CheckoutEnd from './pages/CheckoutEnd';
import customerIsAuthenticated from './services/auth';

const Routes = () => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={props =>
                customerIsAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
            }
        />
    );

    return (
        <BrowserRouter>

            <Header />
            <ToastContainer />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/cardapio" component={Cardapio} />
                <Route exact path="/novidades" component={Novidades} />
                <Route exact path="/recuperacao-de-senha" component={RecoveryPassword} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <PrivateRoute exact path="/pedidos" component={Pedidos} />
                <PrivateRoute exact path="/checkout/entrega" component={Delivery} />
                <PrivateRoute exact path="/checkout/pagamento" component={Payment} />
                <PrivateRoute exact path="/checkout/paypal" component={Paypal} />
                <PrivateRoute path="/sucesso/:id" component={CheckoutEnd} />
                <Route exact path="/politica-de-privacidade" component={Politica} />
                <Route exact path="/termos-e-condicoes" component={Termos} />
                <Route exact path="/quem-somos" component={QuemSomos} />
                <Route exact path="/fale-conosco" component={Contact} />
            </Switch>
            <Footer />
        </BrowserRouter>
    )
}
export default Routes;