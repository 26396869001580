import { Col, Container, Row } from "reactstrap";


const ItemCardapio = ({ product }) => {
    return (
        <div className="cookie" >
            <div className="top-page"
                style={{
                    color: product.color_font,
                    background: product.color_up
                }}>
                <Container >
                    <Row>
                        <Col sm={7}>
                            <div className="name-cookie">
                                <h3>{product.subtitle}</h3>
                                <h1>{product.name}</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <div className="about-cookie">
                                <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="cookies-moving" style={{ backgroundImage: `url(${product.main_banner})` }}>
                <img src={product.main_image} className="cookieImg" alt={product.name} />
            </div>
        </div>
    );
}

export default ItemCardapio;