import axios from 'axios';
import { customerGetToken } from './auth';
const base = process.env.REACT_APP_URL;

const api = axios.create({
    baseURL: `${base}/api`
});


api.interceptors.request.use(async config => {
    const token = customerGetToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use((response) => {
    return response
}, function (error) {
    console.log(error);
    if (error.response.status === 401 && window.location.href.indexOf("login") <= -1) { // if the error is 401 and hasent already been retried
        // customerLogout();
        console.log(error.response);
    }

    return Promise.reject(error);
})


export default api;