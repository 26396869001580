import React, { Component } from "react";

import arrow from '../../images/arrow-left.png';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Container, Row, Col} from 'reactstrap';

import cookie from '../../images/cookie.png';
import bigArrow from '../../images/big-arrow.png';


import './style.css';

export default class SideModalBaseWidth extends Component {
    constructor(props) {
        super(props);
      }  
      render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 3,
            adaptiveHeight: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          };
          return (
              <>
                <div id="sidemodal-bg">
                    <div className="sidemodalwidth">
                            <div id="sidemodal-header">
                                <button><img src={arrow} alt="Voltar" /></button>
                                <h5>Caixinha do João</h5>
                            </div>
                        <div id="sidemodal-body">
                            <Container>
                                <Slider {...settings}>
                                    <div className="box-slider">
                                        <img src={cookie} alt="Cookie" />
                                        <p className="nameCookie">MILLE TRI CHOQUI</p>
                                        <p className="descriptionCookie">Cookie tradicional recheado com cookie chocolate e chocolate branco.</p>
                                        <p className="priceCookie">R$8,90</p>
                                        <form>
                                            <button className="btnsQtd">-</button>
                                            <input type="text" name="qtd" />
                                            <button className="btnsQtd">+</button>
                                        </form>
                                    </div>
                                    <div className="box-slider">
                                        <img src={cookie} alt="Cookie" />
                                        <p className="nameCookie">MILLE TRI CHOQUI</p>
                                        <p className="descriptionCookie">Cookie tradicional recheado com cookie chocolate e chocolate branco.</p>
                                        <p className="priceCookie">R$8,90</p>
                                        <form>
                                            <button className="btnsQtd">-</button>
                                            <input type="text" name="qtd" />
                                            <button className="btnsQtd">+</button>
                                        </form>
                                    </div>
                                    <div className="box-slider">
                                        <img src={cookie} alt="Cookie" />
                                        <p className="nameCookie">MILLE TRI CHOQUI</p>
                                        <p className="descriptionCookie">Cookie tradicional recheado com cookie chocolate e chocolate branco.</p>
                                        <p className="priceCookie">R$8,90</p>
                                        <form>
                                            <button className="btnsQtd">-</button>
                                            <input type="text" name="qtd" />
                                            <button className="btnsQtd">+</button>
                                        </form>
                                    </div>
                                    <div className="box-slider">
                                        <img src={cookie} alt="Cookie" />
                                        <p className="nameCookie">MILLE TRI CHOQUI</p>
                                        <p className="descriptionCookie">Cookie tradicional recheado com cookie chocolate e chocolate branco.</p>
                                        <p className="priceCookie">R$8,90</p>
                                        <form>
                                            <button className="btnsQtd">-</button>
                                            <input type="text" name="qtd" />
                                            <button className="btnsQtd">+</button>
                                        </form>
                                    </div>
                                    <div className="box-slider">
                                        <img src={cookie} alt="Cookie" />
                                        <p className="nameCookie">MILLE TRI CHOQUI</p>
                                        <p className="descriptionCookie">Cookie tradicional recheado com cookie chocolate e chocolate branco.</p>
                                        <p className="priceCookie">R$8,90</p>
                                        <form>
                                            <button className="btnsQtd">-</button>
                                            <input type="text" name="qtd" />
                                            <button className="btnsQtd">+</button>
                                        </form>
                                    </div>
                                    <div className="box-slider">
                                        <img src={cookie} alt="Cookie" />
                                        <p className="nameCookie">MILLE TRI CHOQUI</p>
                                        <p className="descriptionCookie">Cookie tradicional recheado com cookie chocolate e chocolate branco.</p>
                                        <p className="priceCookie">R$8,90</p>
                                        <form>
                                            <button className="btnsQtd">-</button>
                                            <input type="text" name="qtd" />
                                            <button className="btnsQtd">+</button>
                                        </form>
                                    </div>
                                </Slider>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="btnCenter">
                                            <button className="btnOrange">
                                                Fechar Caixinha
                                                <img src={bigArrow} alt="Icon" />
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </>
          )
      }
}