import React, { useEffect, useState } from 'react';
import './style.css';

import { Container, Row, Col } from 'reactstrap';
import api from '../../services/api';

const QuemSomos = () => {
    const [about, setAbout] = useState(null);

    useEffect(() => {
        api.get('about')
            .then(response => setAbout(response.data));
    }, []);

    return (
        <>
            <div className="backgroundPage pagesTerms">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <div className="namePage">
                                <h1>QUEM SOMOS</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="sectionTerms">
                <Container>
                    <Row>
                        <Col sm={12}>
                            {about !== null &&
                                <span dangerouslySetInnerHTML={{ __html: about.description }} />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default QuemSomos;