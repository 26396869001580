import React, { useEffect, useState } from "react";

import './style.css';

import Slider from "react-slick";
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import api from "../../services/api";

import bigArrow from '../../images/big-arrow.png';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";
import { convertDate, getAvailablePeriods, getHours } from "../../services/helpers";
import moment from "moment";
import 'moment/locale/pt-br';
moment.locale('pt-br');

const OrderWithDrawal = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };

    const [dateWithdrawal, setDateWithdrawal] = useState('');
    const [periodWithdrawal, setPeriodWithdrawal] = useState('');
    const [isFind, setIsFind] = useState(true);
    const [closedDays, setClosedDays] = useState(null);
    const [availability, setAvailability] = useState(null);
    const [filteredDates, setFilteredDates] = useState([]);
    const [loadingDates, setLoadingDates] = useState(true);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [hour, setHour] = useState(null);

    const {
        updateDate,
        updateHour,
        updateShowOrderWithDrawal,
        updateShowOrderName,
        updateCart,
    } = useHeader();

    useEffect(() => {
        if (dateWithdrawal !== '' && periodWithdrawal !== '') {
            setIsFind(false);
        }
    }, [dateWithdrawal, periodWithdrawal]);

    useEffect(() => {
        getDates();
    }, []);

    useEffect(() => {
        if(closedDays !== null && availability !== null && hour !== null){
            getFilteredDates();
        }
    }, [closedDays, availability, hour]);

    const getFilteredDates = () => {
        var dateArray = [];
        var currentDate = moment();

        var stopDate = moment().add(30,'days');

        while (currentDate <= stopDate) {
            let toArr = moment(currentDate).format('YYYY-MM-DD');
            let formated = moment(currentDate).locale('en').format('dddd').toLocaleLowerCase();
            if(!closedDays.includes(toArr) && (availability[formated].length > 0)){
                let availablePeriods = getAvailablePeriods(hour);
                if(currentDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')){
                    if(availablePeriods.some(r=> availability[formated].indexOf(r) >= 0)){
                        dateArray.push( toArr );
                    }
                } else {
                    dateArray.push( toArr );
                }
            }
            currentDate = moment(currentDate).add(1, 'days');
        }
        setFilteredDates(dateArray);
        setLoadingDates(false);
    }

    const getDates = () => {
        api.get('/closeddays').then(res => {
            setClosedDays(res.data);
        });
        api.get('/availability').then(res => {
            setAvailability(res.data.days);
            setHour(res.data.current_hour);
        });
    }

    const handleChange = e => {
        let value = e.target.value;
        setPeriodWithdrawal(value);
    }
    const validate = () => {
        if (dateWithdrawal === '' || dateWithdrawal === null) {
            toast.error("Informe a data desejada");
            return false;
        }
        if (periodWithdrawal === '' || periodWithdrawal === null) {
            toast.error("Informe o período desejado");
            return false;
        }
        return true;
    }

    const handleWithDrawal = () => {
        const valid = validate();
        if (valid) {
            console.log('PeriodWithdrawal', periodWithdrawal);
            updateDate(dateWithdrawal);
            updateHour(periodWithdrawal);
            updateShowOrderWithDrawal(false);
            updateShowOrderName(true);
            updateCart(dateWithdrawal, periodWithdrawal);
        }
    }

    const handleDate = date => {
        setAvailableTimes([]);
        setDateWithdrawal(date);
        getAvaiableTimes(date);
    }

    const getAvaiableTimes = date => {
        var availablePeriods = getAvailablePeriods(hour);
        let formated = moment(date).locale('en').format('dddd').toLocaleLowerCase();
        let at = [];
        let hours = getHours();
        hours.map((h, index) => {
            let i = index + 1;
            if(availability[formated].includes(i)){
                if(moment().format('YYYY-MM-DD') === date){
                    if(availablePeriods.includes(i)){
                        at.push({name:  `Das ${h.init.format('HH:mm')} às ${h.final.format('HH:mm')}`, value: i});
                    }
                } else {
                    at.push({name:  `Das ${h.init.format('HH:mm')} às ${h.final.format('HH:mm')}`, value: i});
                }
            }
        });

        setAvailableTimes(at);
    }


    return (
        <SideModalBase
            header={true}
            title='Pedido'
            actual='order_withdrawal'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder" style={{ marginBottom: 0 }}>
                            <p>Você pode escolher o melhor período para vir</p>
                            <span>Data</span>
                        </div>
                        {loadingDates &&
                            <p>Estamos procurando as datas disponíveis, por favor, aguarde alguns segundos...</p>
                        }
                        {!loadingDates &&
                            <>
                            <div className="sliderDate">
                                <Slider {...settings}>
                                    {filteredDates.map((date, key) => (
                                        <div className={`boxDate ${dateWithdrawal === date ? 'active' : ''}`} key={key} onClick={() => handleDate(date)}>
                                            <p>
                                                {moment().format('YYYY-MM-DD') === date &&
                                                    "Hoje"
                                                }
                                                {moment().format('YYYY-MM-DD') !== date &&
                                                    `${moment(date).format('ddd')}`
                                                }
                                            </p>
                                            <h4>{moment(date).format('DD')}</h4>
                                            <small>{moment(date).format('MMM')}</small>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            {availableTimes.length > 0 &&
                                <>
                                    <div className="boxQuestionOrder" style={{ marginBottom: 0 }}>
                                        <span>Período</span>
                                    </div>
                                    
                                    <Form>
                                        <FormGroup>
                                            <Input type="select"
                                                name="periodWithdrawal"
                                                id="periodWithdrawal"
                                                value={periodWithdrawal}
                                                onChange={handleChange}
                                            >
                                                <option disabled value="">Selecione...</option>
                                                {availableTimes.map((at, index) => (
                                                    <option key={index} value={at.value}>{at.name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </>
                            }
                        </>
                        }
                    </Col>
                </Row>
                <button className="btnOrange" onClick={handleWithDrawal} disabled={isFind}>
                    Continuar
                        <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}

export default OrderWithDrawal;