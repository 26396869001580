import React from "react";
import { useHistory } from "react-router";
import { useHeader } from "../../context/header";
import { validatedToken } from "../../services/auth";

import { BsArrowLeftShort } from 'react-icons/bs';

import './style.css';


const SideModalBaseCheckout = ({ actual, header, title, children }) => {
    const { updateShowBag } = useHeader();

    const history = useHistory();
    const back = async () => {
        if (actual === 'login') {
            updateShowBag(true);
            history.push('/');
        }
        if (actual === 'register' || actual === 'finish') {
            history.push('/login');
        }
        if (actual === 'delivery_data') {
            const isSign = await validatedToken();
            if (isSign) {
                updateShowBag(true);
                history.push('/');
            } else {
                history.push('/login');
            }
        }
        if (actual === 'payment') {
            history.push('/checkout/entrega')
        }
        if (actual === 'paypal') {
            history.push('/checkout/pagamento')
        }
        if (actual === 'finish') {
            history.push('pedidos');
        }
    }
    return (
        <>
            <div id="sidemodal-bg-checkout">
                <div id="sidemodal">
                    {header &&
                        <div id="sidemodal-header">
                            <button onClick={back}><BsArrowLeftShort /></button>
                            <h5>{title}</h5>
                            <a id="back-site" href="/">voltar ao site</a>
                        </div>
                    }
                    <div id="sidemodal-body">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideModalBaseCheckout;