const app = '@mille-cuca';

export const storageSetItem = (key, value) => {
    localStorage.setItem(`${app}/${key}`, JSON.stringify(value));
}

export const storageGetItem = (key) => {
    return JSON.parse(localStorage.getItem(`${app}/${key}`));
}

export const storageRemoveItem = (key) => {
    localStorage.removeItem(`${app}/${key}`);
}
