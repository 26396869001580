import React, { useEffect, useState } from "react";

import { useLocation } from 'react-router-dom';

import './style.css';

import { Link } from "react-router-dom";

import logo from '../../images/logo.svg';
import bag from '../../images/bag-mobile.png';

import Bag from '../Bag';
import Slider from '../CarouselProduct';
import Order from '../Order';
import OrderCity from '../OrderCity';
import OrderCep from '../OrderCep';
import OrderDate from '../OrderDate';
import OrderName from '../OrderName';
import OrderGift from '../OrderGift';
import SideModalBaseWidth from '../SideModalBaseWidth';
import { storageGetItem } from "../../services/storage";
import OrderWithDrawal from "../OrderWithDrawal";
import { useHeader } from "../../context/header";
import OrderEnd from "../OrderEnd";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [itemCart, setItemCart] = useState(0);
    const { updateShowBag, updateShowOrder, showBag, showBox, showOrder, showOrderCity, showOrderWithDrawal, showOrderCep, showOrderDate, showOrderName, showOrderGift, showOrderEnd, showSideModalBaseWidth } = useHeader();

    const location = useLocation();
    const current = location.pathname;

    const pathsdark = ['/fale-conosco', '/quem-somos', '/termos-e-condicoes', '/politica-de-privacidade'];

    useEffect(() => {
        let c = storageGetItem('cart');
        setItemCart(c?.items.length ?? 0);
    }, []);

    const handleShowBag = () => {
        // if (itemCart > 0) {
        //     updateShowBag(true);
        // }
        updateShowOrder(true);
    }
    useEffect(() => {
        let body = document.querySelector("body");
        if (showBag || showBox || showOrder || showOrderCity || showOrderWithDrawal || showOrderCep || showOrderDate || showOrderName || showOrderGift || showOrderEnd || showSideModalBaseWidth) {
            body.classList.add("overflow-h");
        } else {
            body.classList.remove("overflow-h");
        }

    }, [showBag, showBox, showOrder, showOrderCity, showOrderWithDrawal, showOrderCep, showOrderDate, showOrderName, showOrderGift, showOrderEnd, showSideModalBaseWidth])
    const handleClose = () => {
        updateShowBag(false);
    }
    return (
        <>
            {showBag &&
                <Bag handleClose={handleClose} setItemCart={setItemCart} />
            }
            {showBox &&
                <Slider />
            }
            {showOrder &&
                <Order />
            }
            {showOrderWithDrawal &&
                <OrderWithDrawal />
            }
            {showOrderCity &&
                <OrderCity />
            }
            {showOrderCep &&
                <OrderCep />
            }
            {showOrderDate &&
                <OrderDate />
            }
            {showOrderName &&
                <OrderName />

            }
            {showOrderGift &&
                <OrderGift />
            }
            {showSideModalBaseWidth &&
                <SideModalBaseWidth />
            }
            {showOrderEnd &&
                <OrderEnd />
            }
            <header id="main-header">
                <Link to="/"><img src={logo} alt="Logo Mille Cuca" /></Link>
                <ul className={pathsdark.includes(current) ? `menu-dark` : 'menu-light'}>
                    <li><Link to="/cardapio">Cookies</Link></li>
                    <li><Link to="/novidades">Novidades</Link></li>
                    <li><Link to="/quem-somos">Quem Somos</Link></li>
                    <li><Link to="/pedidos">Meus Pedidos</Link></li>
                    <li><Link to="/fale-conosco">Fale Conosco</Link></li>
                    <li onClick={handleShowBag}>
                        Sua Caixinha
                        <div id="countCart" >{itemCart}</div>
                    </li>
                </ul>
            </header>

            <header id="header-mobile">
                <Link to="/"><img id="logo" src={logo} alt="Logo Mille Cuca" /></Link>
                <button id="btn-menu-toggle" type="button" onClick={() => setShowMenu(!showMenu)}>
                    <div className="row-menu-toggle"></div>
                    <div className="row-menu-toggle"></div>
                    <div className="row-menu-toggle"></div>
                </button>
                <img id="bag" onClick={handleShowBag} src={bag} alt="Sacola" />
                <div id="countMobile">{itemCart}</div>
                <div className={`menu-mobile${showMenu ? ' open' : ' closed'}`}>
                    {/*<div className="box-title-menu-mob">
                        <strong>Menu</strong>
                    </div>*/}
                    <ul id="main-menu-mobile">
                        <li><a href="/cardapio">Cookies</a></li>
                        <li><a href="/novidades">Novidades</a></li>
                        <li><a href="/quem-somos">Quem Somos</a></li>
                        <li><a href="/pedidos">Meus Pedidos</a></li>
                        <li><a href="/fale-conosco">Fale Conosco</a></li>
                    </ul>
                </div>
            </header>
        </>
    );
}
export default Header;