import React, {  useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RecoveryPassword = () => {

    const [email, setEmail] = useState('');
    const [title] = useState("Recuperação");
    const [textButton] = useState("Continuar");


    const handleChange = (e) => {
        if (e.target.name === 'email') {
            setEmail(e.target.value);
        }
    }

    const next = (e) => {
        toast.success("E-mail de recuperação enviado");
    }

    return (
        <SideModalBaseCheckout
            header={true}
            title={title}
            actual='login'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="questionOrder center">
                            <span>Informe seus dados de acesso</span>
                        </div>
                        <Form>
                            <FormGroup className="checkoutLogin">
                                <Label htmlFor="email">Seu e-mail</Label>
                                <Input type="email" value={email} onChange={handleChange} name="email" id="email" placeholder="Ex: joaoalves@email.com.br" />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={6} >
                        <button className="button-link" onClick={() => window.location.href='/register'}>Não sou cadastrado</button>
                    </Col>
                    <Col sm={6} xs={6} >
                        <button className="button-link" id="forgotPassword"  onClick={() => window.location.href='/login'}>Fazer login</button>
                    </Col>
                </Row>
                <button className="btnOrange" onClick={next} disabled={textButton === "Continuar" ? false : true}>
                    {textButton}
                    <img src={bigArrow} alt="Icon" />
                </button>
                {/* <button className="button-link" id="finishLater" href="#">finalizar mais tarde</button> */}
            </Container>
        </SideModalBaseCheckout>
    );

}
export default RecoveryPassword;