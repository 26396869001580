import React, { useEffect, useState } from "react";
import { useHeader } from "../../context/header";

import { storageGetItem } from "../../services/storage";
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify';

import { BsArrowLeftShort } from 'react-icons/bs';

import './style.css';

const SideModalBase = ({ edit, header, title, children, actual, handleClose, handleBox = null }) => {
    const [isEdit, setIsEdit] = useState(false);
    useEffect(() => {
        setIsEdit(typeof edit !== 'undefined' && edit !== null);
    }, [edit]);
    const {
        updateShowOrder,
        updateShowBag,
        updateShowOrderCity,
        updateShowOrderWithDrawal,
        updateShowOrderCep,
        updateShowOrderDate,
        updateShowOrderName,
        updateShowOrderGift,
        updateShowBox,
        itemToEdit,
        deleteCart,
    } = useHeader();

    const closeModal = (e) => {
        updateShowOrder(false);
        updateShowBag(false);
        updateShowOrderCity(false);
        updateShowOrderWithDrawal(false);
        updateShowOrderCep(false);
        updateShowOrderDate(false);
        updateShowOrderName(false);
        updateShowOrderGift(false);
        updateShowBox(false);
    }

    const back = () => {
        const cart = storageGetItem('cart');


        switch (actual) {
            case 'bag':
                handleClose();
                break;
            case 'order':
                updateShowOrder(false);
                updateShowBag(true);
                break;
            case 'order_city':
                updateShowOrder(true);
                updateShowOrderCity(false);
                break;
            case 'order_withdrawal':
                updateShowOrder(true);
                updateShowOrderWithDrawal(false);
                break;
            case 'order_cep':
                updateShowOrderCity(true);
                updateShowOrderCep(false);
                break;
            case 'order_date':
                updateShowOrderCep(true);
                updateShowOrderDate(false);
                break;
            case 'order_name':
                if (itemToEdit !== null || cart !== null) {
                    updateShowBag(true);
                } else {
                    updateShowOrderDate(true);
                }
                updateShowOrderName(false);
                break;
            case 'order_gift':
                updateShowOrderName(true);
                updateShowOrderGift(false);
                break;
            case 'carousel':
                if (typeof edit !== 'undefined') {
                    handleBox();
                } else {
                    updateShowOrderGift(true);
                    updateShowBox(false);
                }
                break;
            default:
                break;
        }
    }

    const handleDelete = edit => {
        console.log(edit);
        if (typeof edit !== undefined) {
            deleteCart(edit);
            updateShowBag(true);
            updateShowOrderName(false);
            updateShowOrderGift(false);
            updateShowBox(false);
            toast.info("Caixinha excluída com sucesso");
        }
    }

    return (
        <div id="sidemodal-bg" onClick={closeModal}>
            <div id="sidemodal" onClick={(e) => e.stopPropagation()}>
                {header &&
                    <div id="sidemodal-header">
                        {actual !== 'order' &&
                            <button onClick={back}>
                                <BsArrowLeftShort />
                            </button>
                        }
                        <h5>{title}
                            {isEdit &&
                                <span onClick={() => handleDelete(edit)} className="icon-delete" ><MdDelete /></span>
                            }
                        </h5>
                        {!isEdit &&
                            <a id="back-site" href="/">voltar ao site</a>
                        }
                    </div>
                }
                <div id="sidemodal-body">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default SideModalBase;
