import React, { useState, useEffect } from 'react';
import './style.css';

import { Link } from "react-router-dom";

import { Container, Row, Col } from 'reactstrap';

import { useHeader } from '../../context/header';
import api from '../../services/api';
const Home = () => {
    const windowWidth = window.innerWidth;

    const { updateShowOrder } = useHeader();
    const [banners, setBanners] = useState([]);
    const [bannerActive, setBannerActive] = useState(0);

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(()=>{
        if(banners.length === 0) return;

        let bannerInterval = setInterval(() => {
            let max = banners.length - 1;
            setBannerActive(prev => {
                let to = prev + 1 > max ? 0 : prev + 1;
                return to;
            });
        }, 1500);
    
        return ()=> {
          clearInterval(bannerInterval);
        };
    }, [banners]);

    const getBanners = () => {
        api.get('/banners').then(res => {
            setBanners(res.data);
        });
    }

    const handleShowBag = () => {
        updateShowOrder(true);
    }

    return (
        <>
            <div id="banner">
                {banners.map((banner, index) => (
                    <img
                        key={index}
                        loading="lazy"
                        src={windowWidth < 500 ? banner.url_mobile : banner.url}
                        className={`img-banner${bannerActive === index ? ' active' : ''}`}
                        alt={banner.name}
                    />
                ))}
                {banners.length > 0 &&
                    <div className="banner-title">
                        <div className="container">
                            <h1>{banners[bannerActive].name}</h1>
                        </div>
                    </div>
                }
            </div>
            
            <Container>
                <div id="buttons">
                    <Row>
                        <Col sm={6}>
                            <Link to="/cardapio">
                                <button className="btns-home" id="btn-menu">CARDÁPIO</button>
                            </Link>
                        </Col>
                        <Col sm={6}>
                            <button className="btns-home" id="btn-make-wish" onClick={handleShowBag}>FAZER PEDIDO</button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}
export default Home;