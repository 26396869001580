import React, { useEffect, useState } from "react";

import './style.css';

import Slider from "react-slick";

import { Container, Row, Col } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import { RiArrowLeftSLine } from 'react-icons/ri';
import { RiArrowRightSLine } from 'react-icons/ri';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";
import api from "../../services/api";

import { convertMoneyUsa, convertMoneyBrazil } from "../../services/helpers";
import { storageGetItem } from "../../services/storage";
import { toast } from "react-toastify";


const SliderProduct = () => {
    const settings = {
        infinite: false,
        centerMode: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: false,
        // prevArrow: <RiArrowLeftSLine />,
        // nextArrow: <RiArrowRightSLine />
    };

    const { getHour, getDate, updateShowBox, updateShowOrderEnd, updateQtd, updateTotal, isGift, itemToEdit, updateItemCart, updateShowBag } = useHeader();
    const [products, setProducts] = useState([]);
    const [qtds, setQtds] = useState([]);
    const [total, setTotal] = useState(0);
    const [name, setName] = useState('');
    const [item, setItem] = useState(null);
    const [textButton, setTextButton] = useState('Continuar');

    useEffect(() => {
        const getProducts = async () => {
            const response = await api.get(`/product/${getDate()}/${getHour()}`);
            setProducts(response.data);
        }
        getProducts();
        handleTotal();
    }, []);
    useEffect(() => {
        if (itemToEdit !== null) {
            setTextButton('Concluir edição');
            const cart = storageGetItem('cart');
            const cItem = cart.items[itemToEdit];
            setName(cItem.name);
            setQtds(cItem.qtd);
            setTotal(cItem.total);
            setItem(cItem);
        }
    }, [itemToEdit]);

    const handleQtd = (e, product) => {
        let q = qtds;
        let qtd = e.target.value;
        if(qtd > product.stock){
            qtd = product.stock;
        }
        q[product.id] = {
            qtd,
            product_id: product.id,
            name: product.name,
            price: convertMoneyUsa(product.price)
        };
        setQtds(q);
        handleTotal(q);
    }
    const minusQtd = (product) => {
        let q = qtds;
        let qtd = q[product.id] ? q[product.id].qtd - 1 : 0;
        if (qtd < 0) qtd = 0;
        q[product.id] = {
            qtd: qtd,
            product_id: product.id,
            name: product.name,
            price: convertMoneyUsa(product.price)
        };
        setQtds(q);
        handleTotal(q);
    }
    const plusQtd = (product) => {
        let q = qtds;
        let qtd = q[product.id] ? parseInt(q[product.id].qtd) + 1 : 1;
        if(qtd > product.stock){
            return;
        }
        q[product.id] = {
            qtd: qtd,
            product_id: product.id,
            name: product.name,
            price: convertMoneyUsa(product.price)
        };
        setQtds(q);
        handleTotal(q);
    }
    const handleTotal = (value) => {
        let sum = 0;
        let gift = isGift === 'block' ? 5 : 0;
        sum = sum + gift;
        if(value !== undefined){
            value.forEach(item => {
                if (item !== null) {
                    sum += (item.qtd * item.price);
                }
            });
        }
        setTotal(convertMoneyBrazil(sum));
    }

    const handleBox = async () => {
        if(total === '0,00' || total === 0){
            alert('Escolha seus cookies para continuar');
            return;
        }
        await updateTotal(total);
        await updateQtd(qtds);
        if (itemToEdit !== null) {
            let cItem = item;
            cItem.total = total;
            cItem.qtd = qtds;
            await updateItemCart(itemToEdit, cItem);
            await updateShowBag(true);
            toast.success("Caixinha atualizada com sucesso");
        } else {
            await updateShowOrderEnd(true);
        }
        await updateShowBox(false);
    }

    return (
        <SideModalBase
            header={true}
            title={'Escolha os cookies'}
            edit={itemToEdit}
            actual='carousel'
            handleBox={handleBox}
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="sidemodal-body-slider">
                            <Slider {...settings} className="sliderModal">
                                {products.map((product, key) => (
                                    <div className="box-slider" key={key}>
                                        <img src={product.main_image} alt={product.name} />
                                        <p className="nameCookie">{product.subtitle} {product.name}</p>
                                        <p className="descriptionCookie" dangerouslySetInnerHTML={{ __html: product.description }} />
                                        <p className="priceCookie">R${product.price}</p>
                                        <p className="stockCookie"><b>{product.stock} {product.stock === 1 ? 'disponível' : 'disponíveis'}</b> nessa data</p>
                                        {product.stock > 0 &&
                                            <form>
                                                <button className="btnsQtd" type="button" onClick={() => minusQtd(product)}>-</button>
                                                <input type="text" name="qtd" value={qtds[product.id] ? qtds[product.id].qtd : 0} onChange={(e) => handleQtd(e, product)} />
                                                <button className="btnsQtd" type="button" onClick={() => plusQtd(product)}>+</button>
                                            </form>
                                        }
                                    </div>
                                ))}

                            </Slider>
                            <div className="afterCarousel">
                                <div className="priceBox">
                                    <p className="allPriceBox">Total da caixa: <span>R$ {total}</span></p>
                                </div>
                            </div>
                            <button className="btnOrange" onClick={handleBox} disabled={qtds.length === 0}>
                                {textButton}
                                <img src={bigArrow} alt="Icon" />
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SideModalBase>
    );
}
export default SliderProduct;