import React from 'react';

import './style.css';

import Routes from './routes';
import { AuthProvider } from './context/auth';
import { HeaderProvider } from './context/header';

const App = () => (
  <HeaderProvider >
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </HeaderProvider>
);

export default App;
