import React, { Component } from 'react';
import './style.css';

import HeaderContext from '../../context/header';
import ReactPageScroller from 'react-page-scroller';

import api from '../../services/api';
import ItemCardapio from '../../components/ItemCardapio';

export default class Cardapio extends Component {
    static contextType = HeaderContext;
    constructor(props) {
        super(props);
        this.state = {
            scrolling: false,
            lastScroll: 0,
            position: 0,
            products: [0, 1],
            currentPage: null,
            updateShowOrder: () => { },
        };
    }
    async componentDidMount() {
        this.includeStyles();

        window.addEventListener('scroll', this.listenToScroll);

        const response = await api.get(`/product/all/all`);
        const { updateShowOrder } = this.context;
        const products = response.data;

        this.setState({ products, updateShowOrder });
    }

    listenToScroll = () => {
        // if(this.state.scrolling) return;

        // this.setState({ scrolling: true });

        // let st = window.pageYOffset || document.documentElement.scrollTop;
        // let position = this.state.position;
        // let to = position;
        // let lastPosition = this.state.products.length - 1;

        // if(st > this.state.lastScroll){
        //     if(position < lastPosition) to = position + 1;
        // } else {
        //     if(position > 0) to = position - 1;
        // }

        // this.setState({ lastScroll: st <= 0 ? 0 : st, position: to }, () => {
        //     console.log(this.state.position);
        // });

        // let el = document.querySelector(`#section-cookie-${to}`);
        // console.log(el);
        // if(el){
        //     let h = el.getBoundingClientRect().height;
        //     let t = el.getBoundingClientRect().top;
        //     animateScrollTo(t , {
        //         speed: 2000
        //     }).then(hasScrolledToPosition => {
        //         this.setState({ scrolling: false });
        //     });
        // }

        // setTimeout(
        //     function() {
        //         this.setState({ scrolling: false });
        //     }
        //     .bind(this),
        //     2000
        // );

    }

    includeStyles = () => {
        let header = document.querySelector('#main-header');
        let footer = document.querySelector('footer');
        footer.style.display = 'none';
        header.style.position = 'fixed';
        // document.body.style.overflow = 'hidden';
    }

    next = () => {
        console.log('teste');
    }

    handleOrder = () => {
        this.state.updateShowOrder(true);
    }

    handlePageChange = number => {
        console.log(number);
        this.setState({ currentPage: number });
    };

    handleBeforePageChange = number => {
        console.log(number);
    };

    render() {
        return (
            <>
                <div id="cookies">
                    <ReactPageScroller
                        pageOnChange={this.handlePageChange}
                        onBeforePageScroll={this.handleBeforePageChange}
                        customPageNumber={this.state.currentPage}
                    >
                        {this.state.products.map((product, key) => (
                            <ItemCardapio product={product} key={key} />
                        ))}
                    </ReactPageScroller >
                </div>
                {/* <div className="box-buttons">
                    <button id="btn-next" onClick={this.next}>
                        <img src={arrow} alt="Arrow" />PRÓXIMO
                    </button>
                    <button id="btn-prev">
                        ANTERIOR <img src={arrowGray} alt="Arrow" />
                    </button>
                </div> */}
                <button type="button" className="make-wish-cookie" onClick={this.handleOrder} > FAZER PEDIDO</button >
            </>
        );
    }
}