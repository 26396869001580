import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import SideModalBase from '../SideModalBase';
import api from '../../services/api';
import { useHeader } from "../../context/header";
import bigArrow from '../../images/big-arrow.png';

import './style.css';

const OrderCity = () => {

    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");

    const { updateShowOrderCity, updateShowOrderCep, updateCity } = useHeader();

    useEffect(() => {
        const getCities = async () => {
            const { data } = await api.get('/city');

            setCities(data);
        }
        getCities();

    }, []);


    const handleCity = (e) => {
        setCity(e.target.value);
        updateCity(e.target.value);
    }
    const validate = () => {
        if (city === '') {
            toast.error("Selecione sua cidade");
            return false;
        }
        return true;
    }

    const next = () => {
        const valid = validate();
        if (valid) {
            updateShowOrderCity(false);
            updateShowOrderCep(true);
        }
    }


    return (
        <SideModalBase
            header={true}
            title='Pedido'
            actual='order_city'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Qual a sua cidade?</p>
                            <span>Selecione abaixo</span>
                        </div>
                        <Form>
                            <FormGroup>
                                <Input type="select" name="city" onChange={handleCity} value={city}>
                                    <option value="" disabled>Selecione..</option>
                                    {cities.map((city, key) => (
                                        <option key={key} value={city.id}>{city.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>

                    </Col>
                </Row>
                <button className="btnOrange" onClick={next}>
                    Continuar
                    <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}
export default OrderCity;