import React from "react";

import './style.css';

import { Container, Row, Col } from 'reactstrap';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";

const OrderEnd = () => {

    const { updateShowOrderEnd, addItemCart, updateShowBag, updateShowOrderName } = useHeader();
    const handleEnd = (type) => {
        updateShowOrderEnd(false);
        if (type === 'finish') {
            updateShowBag(true);

            updateShowOrderName(false);
        } else {
            updateShowBag(false);
            updateShowOrderName(true);
        }
        addItemCart();
    }
    return (
        <SideModalBase
            header={true}
            title='Pedido'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Caixinha criada com sucesso</p>
                            <span>O que você gostaria de fazer agora?</span>
                        </div>
                        <button className="btnWhite" onClick={() => handleEnd('finish')}>
                            Concluir pedido
                        </button>
                        <button className="btnWhite" onClick={() => handleEnd('new')}>
                            Criar outra caixinha
                        </button>
                        <p className="textAlert">
                            Você pode criar outras caixinhas para seus amigos e/ou familiares e receber todas elas juntas.
                        </p>
                    </Col>
                </Row>
            </Container>
        </SideModalBase>
    );
}
export default OrderEnd;