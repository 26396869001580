import React, { useState, useEffect } from "react";
import { useHeader } from '../../context/header'
import { Container, Row, Col } from 'reactstrap';
import { storageGetItem } from '../../services/storage';

import bigArrow from '../../images/big-arrow.png';
import SideModalBase from '../SideModalBase';

import './style.css';

const Order = (props) => {

    const { updateDelivery, updateShowOrderCity, updateShowOrder, updateShowBag, updateShowOrderWithDrawal } = useHeader();

    const [isFind, setIsFind] = useState(true);
    const [delivery, setDelivery] = useState('');
    const handleDelivery = (typeDelivery) => {
        setDelivery(typeDelivery);
        updateDelivery(typeDelivery);
        setIsFind(false);
    }

    const next = () => {
        if (delivery === 'delivery') {
            updateShowOrderCity(true);
        } else if (delivery === 'withdrawal') {
            updateShowOrderWithDrawal(true);
        }
        updateShowOrder(false);
    }

    useEffect(() => {
        const cart = storageGetItem('cart');
        const totalItems = cart?.items ? cart.items.length : 0;
        if (totalItems > 0) {
            updateShowOrder(false);
            updateShowBag(true);
        }
    })
    return (
        <SideModalBase
            header={true}
            title='Pedido'
            actual='order'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Primeiro, vamos criar sua caixinha</p>
                            <span>Será para entrega ou retirada?</span>
                        </div>
                        <button className={`btnWhite ${delivery === 'delivery' ? 'active' : ''}`} onClick={() => handleDelivery('delivery')}>
                            Entrega
                            </button>
                        <button className={`btnWhite ${delivery === 'withdrawal' ? 'active' : ''}`} onClick={() => handleDelivery('withdrawal')}>
                            Retirada
                            </button>
                        {/* <div className="withdrawal">
                            <p>
                                <span>A entrega</span> é feita no seu endereço e avisada com antecedência.
                                </p>
                        </div> */}
                    </Col>
                </Row>
                <button className="btnOrange" disabled={isFind} onClick={next}>
                    Continuar
                        <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}
export default Order;