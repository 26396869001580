import bigArrow from '../../images/big-arrow.png';
import { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'reactstrap';
import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import { getCustomer } from '../../services/auth';
import { storageGetItem, storageRemoveItem } from '../../services/storage';
import api from '../../services/api';
import { useHistory } from "react-router";

const PayPal = () => {
    const [cart, setCart] = useState([]);
    const [ppp, setPpp] = useState(null);
    const [paymentId, setPaymentId] = useState('');

    const [total] = useState(storageGetItem('total'));
    const history = useHistory();


    useEffect(() => {
        let cCart = storageGetItem('cart');
        let cCustomer = getCustomer();

        setCart(cCart);
        processPayPal(cCart, cCustomer);

    }, []);

    const processPayPal = async (cCart, cCustomer) => {
        let data = {
            cart: cCart,
            total
        }
        const response = await api.post(`paypal/invoice`, {
            data
        });
        console.log(response.data);
        if (typeof response.data.links !== 'undefined') {

            setPaymentId(response.data.id);
            let cPpp = window.PAYPAL.apps.PPP({
                "approvalUrl": response.data.links[1].href,
                "placeholder": "ppplus",
                "mode": "live",
                "payerEmail": cCustomer.email,
                "payerFirstName": cCart.firstNameCard,
                "payerLastName": cCart.lastNameCard,
                "payerTaxId": "41872846807",
                "language": "pt_BR",
                "country": "BR"
            });
            setPpp(cPpp);
        }
    }


    const next = () => {
        ppp.doContinue();
        if (window.addEventListener) {
            window.addEventListener("message", messageListener, false);
        } else if (window.attachEvent) {
            window.attachEvent("onmessage", messageListener);
        } else {
            throw new Error("Can't attach message listener");
        }
    }
    async function messageListener(event) {
        try {
            //this is how we extract the message from the incoming events, data format should look like {"action":"inlineCheckout","checkoutSession":"error","result":"missing data in the credit card form"}
            let data = JSON.parse(event.data);
            if (data.result.state === 'APPROVED') {
                const response = await api.post(`paypal/payment`, {
                    paymentId,
                    payerId: data.result.payer.payer_info.payer_id
                });
                if (response.data.state === 'approved') {
                    await processPayment(response.data.id, response.data.state);
                }
            }
            //insert logic here to handle success events or errors, if any
        } catch (exc) { }
    }
    const processPayment = async (paypalId, paypalStatus) => {
        try {
            let data = {
                cart,
                sameAddress: cart.sameAddress,
                cep: cart.cep,
                street: cart.street,
                number: cart.number,
                state: cart.state,
                district: cart.district,
                city: cart.city,
                complement: cart.complement,
                paypalId,
                paypalStatus,
                paymentMethod: 'card',
                total
            }
            const response = await api.post(`order`, {
                cart,
                howPay: 'now',
                data
            });

            if (response.status === 201) {
                storageRemoveItem('cart');
                history.push(`/sucesso/${response.data.order.id}`);
            }
        } catch (error) {
            console.log(error.response)
        }
    }
    return (
        <SideModalBaseCheckout
            header={true}
            title='Pagamento via PayPal'
            actual='paypal'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <Form id="formCard">
                            <div id="ppplus"></div>
                        </Form>
                    </Col>
                </Row>
                <button className="btnOrange" onClick={next} disabled={paymentId === ''}>
                    Continuar
                    <img src={bigArrow} alt="Icon" />
                </button>
                {/* <button className="button-link" id="finishLater" >finalizar mais tarde</button> */}
            </Container>
        </SideModalBaseCheckout>
    );
}

export default PayPal;