import React, { useEffect, useState } from "react";

import { useLocation } from 'react-router-dom';

import './style.css';

import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import { Link } from "react-router-dom";
import api from '../../services/api';
import { toast } from 'react-toastify';

import logoFooter from '../../images/logo-footer.svg';
import arrow from '../../images/arrow.png';
import insta from '../../images/img-insta.png';
import whats from '../../images/btn-whats.png';
import { cleanString } from "../../services/helpers";

const Footer = () => {
    const [configuration, setConfiguration] = useState({});
    const [accept, setAccept] = useState(false);
    const [email, setEmail] = useState('');

    const location = useLocation();
    const current = location.pathname;

    useEffect(() => {
        api.get(`configuration`).then(response => setConfiguration(response.data));
    }, [])

    const redirectWhatsApp = () => {
        let cellphone = cleanString(configuration.cellphone);

        window.open(`https://wa.me/55${cellphone}?text=Gostaria%20de%20saber%20mais%20sobre%20os%20produtos%20da%20Mille%20Cuca`);
    }

    const validate = () => {
        if (email === '') {
            toast.error("Informe seu e-mail");
            return false;
        }
        if (!accept) {
            toast.error("É preciso aceitar as políticas");
            return false;
        }
        return true;
    }

    const handleNewsletter = async (e) => {
        e.preventDefault();
        const valid = validate();

        if (valid) {
            const response = await api.post('newsletter', {
                accept,
                email
            });
            if (response.status === 201) {
                toast.success("Obrigado por se inscrever!");
            }
        }
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const handleAccept = (e) => {
        setAccept(!accept);
    }
    return (
        <footer style={current === '/cardapio' ? {display: 'none'} : {}}>
            <Container>
                <Row>
                    <Col sm={4}>
                        <h3 className="title-footer">INSCREVA-SE</h3>
                        <Form onSubmit={handleNewsletter}>
                            <FormGroup>
                                <Label for="input-news">Receba lançamentos e promoções</Label>
                                <div className="input-news-content">
                                    <Input type="email" name="email" id="input-news" value={email} onChange={handleEmail} placeholder="Seu e-mail" />
                                    <button><img src={arrow} alt="Enviar" /></button>
                                </div>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={accept} onChange={handleAccept} id="accept" />
                                Aceito as políticas de privacidade
                                </Label>
                            </FormGroup>
                        </Form>
                        <ul>
                            <li><a href="/quem-somos">QUEM SOMOS</a></li>
                            {/* <li><Link to="/termos-e-condicoes">TERMOS E CONDIÇÕES DE USO</Link></li> */}
                            <li><a href="/politica-de-privacidade">POLÍTICA DE PRIVACIDADE</a></li>
                            <li><a href="/fale-conosco">FALE CONOSCO</a></li>
                        </ul>
                    </Col>
                    <Col sm={{ size: 4 }}>
                        <h3 className="title-footer">INSTAGRAM</h3>
                        <a href="https://www.instagram.com/millecuca" target="_blank">
                            <img src={insta} alt="Insta" style={{ width: '100%' }} />
                        </a>
                    </Col>
                    <Col sm={{ size: 4 }}>
                        <h3 className="title-footer">LOJA</h3>
                        <p className="info-store" >
                            {configuration.street}, {configuration.number}<br />
                            {configuration.district}<br />
                            {configuration.city} - {configuration.uf}<br />
                            {configuration.zip_code}
                        </p>
                        <p className="info-store" dangerouslySetInnerHTML={{ __html: configuration.opening_hours }} />
                        <img src={whats} className="whatsapp" alt="Whatsapp" onClick={redirectWhatsApp} style={{ width: '100%', maxWidth: '142px', marginTop: '13px' }} />
                    </Col>
                </Row>
                <div id="credits">
                    <Row>
                        <Col sm={8}>
                            <img src={logoFooter} alt="Logo" />
                        </Col>
                        <Col sm={4}>
                            <p>Copyright © 2020 Mille Cuca.<br />Todos os direitos reservados.</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;