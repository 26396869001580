import React, { useEffect, useState } from 'react';
import './style.css';

import { Container, Row, Col } from 'reactstrap';
import api from '../../services/api';

const Contact = () => {
    const [configuration, setConfiguration] = useState({});

    useEffect(() => {
        api.get('configuration')
            .then(response => setConfiguration(response.data));
    }, []);

    return (
        <>
            <div className="backgroundPage pagesTerms">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <div className="namePage">
                                <h1>FALE CONOSCO</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="sectionTerms">
                <Container>
                    <Row>
                        <Col sm={6}>
                            <h3>MILLE CUCA</h3>
                            <p>
                                {configuration.street}, {configuration.number}<br />
                                {configuration.district}<br />
                                {configuration.city} - {configuration.uf}<br />
                                {configuration.zip_code}
                            </p>
                            <p className="opHours" dangerouslySetInnerHTML={{ __html: configuration.opening_hours }} />
                            <p>{configuration.email} - {configuration.cellphone}</p>
                        </Col>
                        {/* <Col sm={6}>
                            img
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Contact;