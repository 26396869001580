import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import { useHeader } from '../../context/header';

import SideModalBase from '../SideModalBase';
import { storageGetItem } from "../../services/storage";
import { convertMoneyUsa, convertMoneyBrazil, getTotalWithShipping } from "../../services/helpers";
import { validatedToken } from "../../services/auth";


const Bag = ({ handleClose, setItemCart }) => {

    const {
        updateShowOrderName,
        updateShowBox,
        updateShowBag,
        updateShowOrder,
        updateItemToEdit,
        updateTotal
    } = useHeader();

    const [cart, setCart] = useState(null);
    const [items, setItems] = useState([]);
    const [zipSet, setZipSet] = useState({});
    const [totalItems, setTotalItems] = useState(0);
    const [total, setTotal] = useState(0);
    const [dateWithdrawal, setDateWithdrawal] = useState('');
    const [periodWithdrawal, setPeriodWithdrawal] = useState('');

    const [activeDelivery, setActiveDelivery] = useState('');
    const [activeWithDrawal, setActiveWithDrawal] = useState('');
    const [delivery, setDelivery] = useState('');
    const [deliveries, setDeliveries] = useState([]);

    const [cep, setCep] = useState('');

    useEffect(() => {
        let c = storageGetItem('cart');
        setCart(c);
        if (c) {
            c.items.map((item, index) => {
                c.items[index].total_w_shipping = getTotalWithShipping(item.total, c.zipSet?.price, c.items.length);
            });

            setItems(c.items);
            setCep(c.cep);
            setZipSet(c.zipSet);
            setDelivery(c.delivery);
            setItemCart(c.items.length);

            let sum = 0;
            let total = 0;

            c.items.forEach((item) => {
                item.qtd.forEach((q) => {
                    if (q !== null) sum = parseInt(q.qtd) + sum
                });
                total += convertMoneyUsa(item.total);
            });
            if (c.delivery === 'delivery') {
                total += convertMoneyUsa(c.zipSet.price ?? 0);
            } else {
                setDateWithdrawal(c.dateWithdrawal);
                setPeriodWithdrawal(c.periodWithdrawal);
            }
            setTotalItems(sum);
            setTotal(convertMoneyBrazil(total));
            setDeliveries(c.city.city_deliveries);
            if (c.delivery === 'delivery') {
                setActiveDelivery('active');
                setActiveWithDrawal('');
            } else {
                setActiveDelivery('');
                setActiveWithDrawal('active');
            }
        }

    }, []);


    const handleChange = (e) => {
        setCep(e.target.value);
    }

    const createBox = () => {
        if (cart !== null) {
            updateShowOrderName(true);
        } else {
            updateShowOrder(true);
        }
        updateShowBag(false);
    }

    const editItem = (item, key) => {
        updateItemToEdit(key);
        // updateShowOrderName(true);
        updateShowBox(true);
        updateShowBag(false);
    }
    const finish = async () => {
        const isSign = await validatedToken();
        updateTotal(total);
        if (isSign) {
            window.location.href='/checkout/entrega';
        } else {
            window.location.href='/login';
        }
        updateShowBag(false);
    }

    return (
        <SideModalBase
            handleClose={handleClose}
            actual="bag"
            header={true}
            title="Pedido"
        >
            <Container>
                <div className="sideModalBag">
                    <Row>
                        <Col sm={12}>
                            {/* <button className={`bg-white ${delivery === 'delivery' ? 'active' : ''}`} onClick={() => handleDelivery('delivery')}>Entrega</button>
                            <button className={`bg-white ${delivery === 'withdrawal' ? 'active' : ''}`} onClick={() => handleDelivery('withdrawal')}>Retirada</button>
                            <button className="btn-close" onClick={handleClose}>fechar</button>*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form>
                                {false &&
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="cep">CEP</Label>
                                                <Input type="text" name="cep" id="cep" value={cep} onChange={handleChange} placeholder="08220-010" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="date">Data para entrega</Label>
                                                <Input type="select" name="date" id="date">
                                                    {deliveries.length > 0 &&
                                                        deliveries.map((item, key) => (
                                                            <option key={key}>28/09/2020</option>
                                                        ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                {false &&
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="dateWithdrawal">Data</Label>

                                                <Input type="select"
                                                    id="dateWithdrawal"
                                                    name="dateWithdrawal"
                                                    value={dateWithdrawal}
                                                    onChange={handleChange}
                                                >
                                                    <option disabled value="">Selecione...</option>
                                                    <option value="2021-03-03">03/03/2021</option>
                                                    <option value="2021-03-04">04/03/2021</option>
                                                    <option value="2021-03-05">05/03/2021</option>
                                                    <option value="2021-03-08">08/03/2021</option>
                                                    <option value="2021-03-09">09/03/2021</option>
                                                    <option value="2021-03-10">10/03/2021</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="periodWithdrawal">Período</Label>
                                                <Input type="select"
                                                    name="periodWithdrawal"
                                                    id="periodWithdrawal"
                                                    value={periodWithdrawal}
                                                    onChange={handleChange}
                                                >
                                                    <option value="manha">Manhã</option>
                                                    <option value="tarde">Tarde</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        </Col>
                    </Row>
                    {zipSet?.price &&
                        <Row>
                            <Col sm={12}>
                                <small>O valor total do frete é de R$ {zipSet.price}.</small>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col sm={12}>
                            <button className="create-box" onClick={createBox}>+ Criar outra caixa</button>
                        </Col>
                    </Row>
                    {items.map((item, key) => (
                        <Row key={key}>
                            <Col sm={12}>
                                <button className="created-box" onClick={() => editItem(item, key)}>
                                    <span>{item.name}</span>
                                    <span>
                                        <small>Total{zipSet?.price ? ' com frete' : ''}</small>
                                        R$ {item.total_w_shipping}
                                    </span>
                                </button>
                            </Col>
                        </Row>
                    ))}
                    <Row>
                        <Col sm={12}>
                            <div id="footer-sidemodal">
                                <div className="total-cookies">
                                    <small>Total de cookies</small>
                                    <p>{totalItems} unids.</p>
                                </div>
                                <div className="total-cookies">
                                    <small>Total</small>
                                    <p>R$ {total}</p>
                                </div>
                                {totalItems !== 0 &&
                                    <button className="finish" onClick={finish}>
                                        Finalizar <img src={bigArrow} alt="Icon" />
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </SideModalBase >
    );
};

export default Bag;