import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';

const AuthContext = createContext({ signed: false, user: null });

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function loadStorageData() {
            const storagedUser = await sessionStorage.getItem('@mille-cuca:customer');
            const storagedToken = await sessionStorage.getItem('@mille-cuca:token');


            if (storagedUser && storagedToken) {
                save(JSON.parse(storagedUser), storagedToken, false);
            }

            setLoading(false);
        }

        loadStorageData();
    }, []);

    if (loading) {
        return (
            <div>
                Carregando...
            </div>
        )
    }

    async function signIn(email, password) {
        const customer = { email, password };
        try {
            const response = await api.post('/customer/login', customer);
            if (response.data.success) {
                const { customer, token } = response.data.success;
                save(customer, token, true);
            }
        } catch (error) {
            alert('Erro ao logar', 'Por favor, verifique os dados e tente novamente.');
        }

    }

    async function save(customer, token, store) {
        setUser(customer);

        api.defaults.headers.Authorization = `Bearer ${token}`;

        if (store) {
            await sessionStorage.setItem('@mille-cuca:customer', JSON.stringify(customer));
            await sessionStorage.setItem('@mille-cuca:token', token);
        }
    }

    function signOut() {
        sessionStorage.clear().then(() => {
            setUser(null);
        });
    }

    return (
        <AuthContext.Provider value={{ signed: !!user, user, signIn, signOut, save }}>
            {children}
        </AuthContext.Provider>
    );

};

export const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
}

export default AuthContext;