import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';
import info from '../../images/info.png';
import upload from '../../images/upload.png';
import qrcode from '../../images/qrcode.png';

import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import InputMask from 'react-input-mask';
import api from "../../services/api";
import { storageGetItem, storageRemoveItem, storageSetItem } from "../../services/storage";
import { useDropzone } from 'react-dropzone';
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import axios from 'axios';
import { getTotalWithShipping, maskCpf } from "../../services/helpers";
import { getCustomer } from '../../services/auth';

const Payment = () => {
    const defaultPaymentMethod = 'card';

    const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
    const [customer, setCustomer] = useState({});
    const [howPay, setHowPay] = useState('now');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cpf, setCpf] = useState('');
    const [cart, setCart] = useState([]);
    const [filesTransference, setFilesTransference] = useState([]);
    const [sameAddress, setSameAddress] = useState(false);
    const [cep, setCep] = useState('');
    const [isDelivery, setIsDelivery] = useState(false);
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [complement, setComplement] = useState('');
    const [textButton, setTextButton] = useState('Continuar');
    const [uploading, setUploading] = useState(false);

    const [showItems, setShowItems] = useState(false);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState('');

    const history = useHistory();

    useEffect(() => {
        setTotal( storageGetItem('total') );

        let c = storageGetItem('cart');
        let customer = getCustomer();

        c.items.map((item, index) => {
            c.items[index].total_w_shipping = getTotalWithShipping(item.total, c.zipSet?.price, c.items.length);
        });

        setItems(c.items);

        setIsDelivery(c.delivery === 'delivery');
        setCart(c);

        setCustomer(customer);
        setFirstName(customer.first_name)
        setLastName(customer.last_name)
        setCpf(customer.cpf);

        // processPayPal(customer, c);

    }, []);
    const handleChange = (e) => {

        if (e.target.name === 'cep') {
            let value = e.target.value;
            let c = value.replace('_', '');
            if (c.length === 9) {
                getCep(e.target.value);
            }
            setCep(e.target.value);
        }
        if (e.target.name === 'firstName') {
            setFirstName(e.target.value);
        }
        if (e.target.name === 'lastName') {
            setLastName(e.target.value);
        }
        if (e.target.name === 'cpf') {
            setCpf(maskCpf(e.target.value));
        }

        if (e.target.name === 'street') {
            setStreet(e.target.value);
        }
        if (e.target.name === 'number') {
            setNumber(e.target.value);
        }
        if (e.target.name === 'state') {
            setState(e.target.value);
        }
        if (e.target.name === 'city') {
            setCity(e.target.value);
        }
        if (e.target.name === 'district') {
            setDistrict(e.target.value);
        }
        if (e.target.name === 'complement') {
            setComplement(e.target.value);
        }

    }

    const getCep = async (cep) => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            setStreet(response.data.logradouro);
            setState(response.data.uf);
            setCity(response.data.localidade);
            setDistrict(response.data.bairro);
        } catch (error) {
            console.log(error.response);
        }
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            handleUpload(acceptedFiles);
        }
    });
    const handleUpload = async files => {
        let success = false;
        let names = [];
        for(let i = 0; i < files.length; i++){
            if (typeof files[i] === 'object') {
                let data = new FormData();
                data.append('file', files[i]);
                setUploading(true);
                setFilesTransference([]);
                try {
                    const res = await api.post(`/customer/upload`, data);
                    names.push(res.data.name);
                    success = true;
                } catch (error) {
                    setUploading(false);
                    const { response } = error;
                    if (response.status === 401) {
                        if (response.data.success === false) {
                            alert(response.data.message);
                        }
                    }
                }
            }
        }

        if(success)
        {
            setFilesTransference(names);
            setUploading(false);
        }
    }
    const validateCard = () => {
        if (paymentMethod !== 'card') {
            return true;
        }
        if (firstName === '') {
            toast.error("O nome é obrigatório");
            return false;
        }
        if (lastName === '') {
            toast.error("O sobrenome é obrigatório");
            return false;
        }
        if (cpf === '') {
            toast.error("O CPF é obrigatório");
            return false;
        }

        if (!sameAddress) {
            if (cep === '') {
                toast.error("Informe o CEP");
                return false;
            }
            if (street === '') {
                toast.error("Informe a rua");
                return false;
            }
            if (number === '') {
                toast.error("Informe o número");
                return false;
            }
            if (state === '') {
                toast.error("Informe o estado");
                return false;
            }
            if (city === '') {
                toast.error("Informe a cidade");
                return false;
            }
        }
        return true;
    }

    const validateTransference = () => {
        if (paymentMethod !== 'transference') {
            return true;
        }
        if (filesTransference.length === 0) {
            toast.error("Enviar comprovante de transferência");
            return false;
        }
        return true;
    }
    const validate = () => {
        const cardValid = validateCard();
        const transferenceValid = validateTransference();
        return paymentMethod !== 'after' ? (cardValid && transferenceValid) : true;
    }

    const next = async () => {
        const valid = validate();
        if (!valid) {
            return;
        }
        if (paymentMethod === 'card') {
            // data = {
            //     cart, nameCard,
            //     numberCard,
            //     expiration,
            //     security,
            //     typeCard,
            //     sameAddress,
            //     cep,
            //     street,
            //     number,
            //     state,
            //     district,
            //     city,
            //     complement,
            //     total
            // }
            cart.firstNameCard = firstName;
            cart.lastNameCard = lastName;
            cart.cpfCard = cpf;
            cart.sameAddress = sameAddress;
            cart.cep = cep;
            cart.street = street;
            cart.number = number;
            cart.state = state;
            cart.district = district;
            cart.city = city;
            cart.complement = complement;
            storageSetItem('cart', cart);
            history.push('/checkout/paypal');
        }
        if (paymentMethod === 'transference') {
            let data = {
                filesTransference: JSON.stringify(filesTransference),
                paymentMethod
            }
            await processPayment(data);
        }
        if (paymentMethod === 'after') {
            let data = {
                paymentMethod
            }
            await processPayment(data);
        }

    }

    const processPayment = async (data) => {
        setTextButton('Carregando...');
        try {
            const response = await api.post(`order`, {
                cart,
                howPay,
                data
            });
            setTextButton('Continuar');
            if (response.status === 201) {
                storageRemoveItem('cart');
                history.push(`/sucesso/${response.data.order.id}`);
            }
        } catch (error) {
            setTextButton('Continuar');
            console.log(error.response)
        }
    }

    const handleInputChange = () => {
        setSameAddress(!sameAddress);
    }
    return (
        <SideModalBaseCheckout
            header={true}
            title='Pagamento'
            actual='payment'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        {/* <div className="questionOrder">
                            {!isDelivery &&
                                <p> Como pagar</p>
                            }
                            <button onClick={() => { setHowPay('now'); setPaymentMethod(defaultPaymentMethod); }} className={`btnCheckout ${howPay === 'now' ? 'active' : ''}`}>
                                Pagar agora
                            </button>
                            {!isDelivery &&
                                <button onClick={() => { setHowPay('after'); setPaymentMethod('after'); }} className={`btnCheckout ${howPay === 'after' ? 'active' : ''}`}>
                                    Na retirada
                                </button>
                            }
                        </div> */}
                        {howPay === 'now' &&
                            <>
                                <div className="questionOrder">
                                    <p>Método de pagamento</p>
                                    <button onClick={() => setPaymentMethod('card')} className={`btnCheckout ${paymentMethod === 'card' ? 'active' : ''}`}>
                                        Cartão
                                    </button>
                                    <button onClick={() => setPaymentMethod('transference')} className={`btnCheckout ${paymentMethod === 'transference' ? 'active' : ''}`}>
                                        Pix
                                    </button>
                                    {/* <button onClick={() => setPaymentMethod('picpay')} className={`btnCheckout ${paymentMethod === 'picpay' ? 'active' : ''}`}>
                                        PicPay
                                    </button> */}
                                </div>
                                <Form id="formCard" style={{ display: `${paymentMethod === 'card' ? 'block' : 'none'}` }}>

                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup >
                                                <Label htmlFor="firstName">Nome</Label>
                                                <Input type="text" value={firstName} onChange={handleChange} name="firstName" id="firstName" placeholder="Ex: Joao" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup >
                                                <Label htmlFor="lastName">Sobrenome</Label>
                                                <Input type="text" value={lastName} onChange={handleChange} name="lastName" id="lastName" placeholder="Ex: Alves" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup >
                                                <Label htmlFor="cpf">CPF</Label>
                                                <Input type="lastName" value={cpf} onChange={handleChange} name="cpf" id="cpf" placeholder="Ex: 000.000.000-00" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <label htmlFor="nameCard">Nome escrito no cartão</label>
                                                <Input type="text" name="nameCard" id="nameCard" value={nameCard} onChange={handleChange} placeholder="Ex: João Barbosa Silva" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <label htmlFor="numberCard">Número do cartão</label>
                                                <Input type="text" name="numberCard" id="numberCard" value={numberCard} onChange={handleChange} placeholder="Ex: 0000 0000 0000 0000" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <label htmlFor="expiration">Vencimento</label>
                                                <Input type="text" name="expiration" id="expiration" value={expiration} onChange={handleChange} placeholder="MM/YYYY" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <label htmlFor="security">CVV</label>
                                                <Input type="text" name="security" id="security" value={security} onChange={handleChange} placeholder="Ex: 123" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="typeCard">
                                                <FormGroup check inline>
                                                    <Label>
                                                        <Input type="radio" name="typeCard" value="credit" checked={typeCard === "credit"} onChange={handleChange} /> Crédito
                                            </Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Label>
                                                        <Input type="radio" name="typeCard" value="debit" checked={typeCard === "debit"} onChange={handleChange} /> Débito
                                            </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col sm={12}>
                                            <p id="pAddress"><b>Endereço de Cobrança</b></p>
                                        </Col>
                                    </Row>
                                    {isDelivery &&
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <span className="giftCheckbox" onClick={handleInputChange}>
                                                        <input type="checkbox"
                                                            checked={sameAddress}
                                                            onChange={handleInputChange} />
                                                        <span></span>
                                                        Mesmo endereço de entrega
                                                    </span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                    {(!sameAddress || !isDelivery) &&
                                        <>

                                            <Row>
                                                <Col sm={6} xs={6}>
                                                    <FormGroup>
                                                        <label htmlFor="cep">CEP</label>
                                                        <InputMask mask="99999-999" value={cep} type="text" name="cep" id="cep" onChange={handleChange} placeholder="Ex: 08220-010">
                                                            {(inputProps) => <Input {...inputProps} />}
                                                        </InputMask>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <label htmlFor="street">Endereço</label>
                                                        <Input type="text" name="street" id="street" value={street} onChange={handleChange} placeholder="Ex: Rua Patativa" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <label htmlFor="district">Bairro</label>
                                                        <Input type="text" name="district" id="street" value={district} onChange={handleChange} placeholder="Ex: Cidade Antônio Estêvão de Carvalho" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4} xs={4}>
                                                    <FormGroup>
                                                        <label htmlFor="number">Número</label>
                                                        <Input type="text" name="number" id="number" value={number} onChange={handleChange} placeholder="Ex: 26" />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={8} xs={8}>
                                                    <FormGroup>
                                                        <label htmlFor="complement">Complemento</label>
                                                        <Input type="text" name="complement" id="complement" value={complement} onChange={handleChange} placeholder="Ex: Apartamento 125" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4} xs={4}>
                                                    <FormGroup>
                                                        <Label htmlFor="state">Estado</Label>
                                                        <Input type="select" name="state" value={state} onChange={handleChange} id="state">
                                                            <option disabled value="">Selecione..</option>
                                                            <option value="AC">AC</option>
                                                            <option value="AL">AL</option>
                                                            <option value="AP">AP</option>
                                                            <option value="AM">AM</option>
                                                            <option value="BA">BA</option>
                                                            <option value="CE">CE</option>
                                                            <option value="DF">DF</option>
                                                            <option value="ES">ES</option>
                                                            <option value="GO">GO</option>
                                                            <option value="MA">MA</option>
                                                            <option value="MT">MT</option>
                                                            <option value="MS">MS</option>
                                                            <option value="MG">MG</option>
                                                            <option value="PA">PA</option>
                                                            <option value="PB">PB</option>
                                                            <option value="PR">PR</option>
                                                            <option value="PE">PE</option>
                                                            <option value="PI">PI</option>
                                                            <option value="RJ">RJ</option>
                                                            <option value="RN">RN</option>
                                                            <option value="RS">RS</option>
                                                            <option value="RO">RO</option>
                                                            <option value="RR">RR</option>
                                                            <option value="SC">SC</option>
                                                            <option value="SP">SP</option>
                                                            <option value="SE">SE</option>
                                                            <option value="TO">TO</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={8} xs={8}>
                                                    <FormGroup>
                                                        <label htmlFor="city">Cidade</label>
                                                        <Input type="text" value={city} name="city" id="city" onChange={handleChange} placeholder="Ex: São Paulo" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    }


                                </Form>
                                <div className="boxTransfer" style={{ display: `${paymentMethod === 'transference' ? 'block' : 'none'}` }}>
                                    <Row>
                                        <Col sm={12}>
                                            <p><strong>Chave Pix: </strong> 25.308.178/0001-25</p>
                                            <p><strong>Favorecido: </strong> Jamile Peixoto Queiroz</p>
                                            <p><strong>Total: </strong> R${total}</p>

                                            <button className="boxes-checkout-btn" onClick={() => setShowItems(prev => !prev)}>Conferir valores das caixinhas</button>
                                            {showItems &&
                                                <table className="boxes-checkout-details">
                                                    {items.map(item => (
                                                        <tr>
                                                            <td>{item.name}</td>
                                                            <td>R$ {item.total_w_shipping}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            }
                                            {/* <img src={qrcode} width={120} style={{ border: 'solid 3px #000', marginBottom: 10 }} alt="Imagem QRCode" /> */}
                                            <Form>
                                                <FormGroup>
                                                    <Label htmlFor="fileTransference" style={{ marginBottom: '5px', fontSize: '15px' }}>Upload do(s) comprovante(s)</Label>
                                                    <p>Se precisar, você pode escolher mais de uma imagem como comprovante.</p>
                                                    {uploading &&
                                                        <p>Enviando comprovante(s)...</p>
                                                    }
                                                    {!uploading &&
                                                        <div {...getRootProps({ className: 'dropzone btnUpload' })}>
                                                            <input {...getInputProps()} />
                                                            <img src={upload} alt="Icon" /><p>{filesTransference.length === 0 ? 'Enviar comprovante(s)' : 'Trocar comprovante(s)'}</p>
                                                        </div>
                                                    }
                                                    {filesTransference.length > 0 &&
                                                        <p style={{ marginTop: 15 }}>Comprovante(s) enviado</p>
                                                    }
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="boxPicPay" style={{ display: `${paymentMethod === 'picpay' ? 'block' : 'none'}` }}>
                                    <Row>
                                        <Col sm={12}>
                                            <img src={info} alt="Icon" />
                                            <p>
                                                Ao clicar no botão, você será redirecionado para o ambiente de pagamento PicPay
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }

                    </Col>
                </Row>
                <button className="btnOrange" onClick={next} disabled={textButton === "Continuar" ? false : true}>
                    {textButton}
                    <img src={bigArrow} alt="Icon" />
                </button>
                {/* <button className="button-link" id="finishLater" >finalizar mais tarde</button> */}
            </Container>
        </SideModalBaseCheckout >
    );
}
export default Payment;