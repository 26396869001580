import React, { useEffect, useState } from 'react';
import './style.css';

import { Container, Row, Col } from 'reactstrap';

import calendar from '../../images/pedidos/calendar.png';
import cookie from '../../images/pedidos/cookie.png';
import total from '../../images/pedidos/total.png';
import status from '../../images/pedidos/status.png';
import api from '../../services/api';

const Pedidos = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = async () => {
        try {
            const response = await api.get('order');
            if (response.status === 200) {
                console.log(response.data);
                setOrders(response.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    return (
        <>
            <div className="backgroundPage" id="page-order">
                <Container>
                    <Row>
                        <Col sm={7}>
                            <div className="namePage">
                                <h1>MEUS PEDIDOS</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="sectionOrder">
                <Container>
                    {orders.map(order => (
                        <Row key={order.id}>
                            <Col sm={12}>
                                <div className="boxOrder">
                                    <Row>
                                        <Col sm={3}>
                                            <span>Data do pedido</span>
                                            <img id="imgCalendar" src={calendar} alt="Icon" />
                                            <p>{order.date_order}</p>
                                        </Col>
                                        <Col sm={2}>
                                            <span>Cookies</span>
                                            <img id="imgCookie" src={cookie} alt="Icon" />
                                            <p>{order.total_items} cookies</p>
                                        </Col>
                                        <Col sm={2}>
                                            <span>Total do pedido</span>
                                            <img id="imgTotal" src={total} alt="Icon" />
                                            <p>R$ {order.total}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <span>Status</span>
                                            <img id="imgStatus" src={status} alt="Icon" />
                                            <p>{order.status_name}</p>
                                        </Col>
                                        {/* <Col sm={2}>
                                            <span>&nbsp;</span>
                                            <a>Ver caixinhas</a>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col>
                                            {order.boxes.map(box => (
                                                <div className="box-container">
                                                    <div className="box">
                                                        <div className="box-header">
                                                            <h2>{box.name}</h2>
                                                        </div>
                                                        <div className="box-body">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nome</th>
                                                                        <th>Quantidade</th>
                                                                        <th>Preço unit.</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {box.items.map(item => (

                                                                        <tr>
                                                                            <td>{item.product_name}</td>
                                                                            <td>{item.qtd}</td>
                                                                            <td>R$ {item.price_unit}</td>
                                                                            <td>R$ {item.subtotal}</td>
                                                                        </tr>
                                                                    ))}
                                                                    {box.gift === 1 &&
                                                                        <tr>
                                                                            <td>Caixinha de presente</td>
                                                                            <td>1</td>
                                                                            <td>R$ 5,00</td>
                                                                            <td>R$ 5,00</td>
                                                                        </tr>
                                                                    }
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><b>R$ {box.total}</b></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    ))}

                </Container>
            </div>
        </>
    );
}

export default Pedidos;