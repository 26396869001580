import React, { useEffect, useState } from "react";

import './style.css';

import { Container, Row, Col } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';
import check from '../../images/check.png';

import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import { useHeader } from "../../context/header";
import { useParams } from "react-router";
import api from "../../services/api";

const CheckoutEnd = () => {

    const [title, setTitle] = useState('');
    const [hour, setHour] = useState('');
    const [date, setDate] = useState('');
    const [order, setOrder] = useState({});

    const { id } = useParams();
    useEffect(() => {
        let body = document.querySelector("body");
        body.classList.add("overflow-h");

        const getOrder = async () => {
            try {
                const response = await api.get(`order/${id}`);
                const cOrder = response.data;
                setOrder(cOrder);
                setDate(cOrder.date);
                let cHour, cTitle = '';
                cHour = cOrder.real_hour;
                if (cOrder.delivery === 'withdrawal') {
                    cTitle = 'retirada';
                } else {
                    cTitle = 'entrega';
                }
                setHour(cHour);
                setTitle(cTitle);
            } catch (error) {
                console.log(error);
            }
        }
        getOrder();
    }, []);


    const finish = () => {
        window.location.href = '/';
    }
    return (
        <SideModalBaseCheckout
            header={true}
            title='Pedido realizado'
            actual='finish'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="questionOrder center">
                            <img src={check} alt="Check" />
                            <p id="orderSuccess">Pedido realizado com sucesso</p>
                        </div>
                        <div className="infoOrder">
                            <p>Número do pedido</p>
                            <h4>{id}</h4>
                        </div>
                        <div className="infoOrder">
                            <p>Total do pedido</p>
                            <h4>R$ {order.total}</h4>
                        </div>
                        <div className="infoOrder">
                            <p>Data da {title}</p>
                            <h4>{date}</h4>
                        </div>
                        <div className="infoOrder">
                            <p>Período de {title}</p>
                            <h4>
                                {hour}
                            </h4>
                        </div>
                        {/* <div className="infoOrder">
                            <a href="#">download do pedido</a>
                        </div> */}
                    </Col>
                </Row>
                <button className="btnOrange checkoutEnd" onClick={finish}>
                    Voltar a navegar
                    <img src={bigArrow} alt="Icon" />
                </button>
                <a id="finishLater" className="seeOrder" href="/pedidos">ver meu histórico de pedidos</a>
            </Container>
        </SideModalBaseCheckout>
    );
}
export default CheckoutEnd;