import api from "./api";

export const customerIsAuthenticated = () => localStorage.getItem('@mille-cuca:token') !== null;

export const customerGetToken = () => localStorage.getItem('@mille-cuca:token');

export const setCustomer = (customer, token) => {
    localStorage.setItem('@mille-cuca:customer', JSON.stringify(customer));
    localStorage.setItem('@mille-cuca:token', token);
}

export const getCustomer = () => {
    let customer = localStorage.getItem('@mille-cuca:customer');

    if (customer === null) {
        return customer;
    }
    return JSON.parse(customer);
}

export const validatedToken = async () => {
    const customer = getCustomer();
    const token = customerGetToken();
    if (customer !== null) {
        try {
            const response = await api.post(`customer/validate`, {
                customer,
                token
            });
            if (response.status === 200) {
                return response.data.success
            }
        } catch (error) {
            console.log(error.response);
            return false;
        }
    }
    return false;
}

export const customerLogout = () => {
    localStorage.removeItem('customer');
    localStorage.removeItem('customerToken');
};

export default customerIsAuthenticated;