import React, { useEffect, useState } from "react";

import './style.css';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container, Row, Col } from 'reactstrap';

import bigArrow from '../../images/big-arrow.png';

import SideModalBase from '../SideModalBase';
import { useHeader } from "../../context/header";
import api from "../../services/api";
const OrderDate = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };

    const [groupDeliveries, setGroupDeliveries] = useState({});
    const [arrDates, setArrDates] = useState([]);
    const [morningHours, setMorningHours] = useState([]);
    const [eveningHours, setEveningHours] = useState([]);
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [isFind, setIsFind] = useState(true);

    const [month] = useState([
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez"
    ]);
    const [dayWeek] = useState([
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sáb",
    ]);

    const { updateShowOrderDate, updateShowOrderName, city, cityData, cep, updateDate, updateHour, updateCart } = useHeader();
    useEffect(() => {
        // let group = city.city_deliveries.reduce((r, a) => {
        //     r[a.date] = [...r[a.date] || [], a];
        //     return r;
        // }, {});
        // setGroupDeliveries(group);
        getCityData(cep, city, new Date());
    }, []);

    const getCityData = async (cep, cityId, date = null) => {
        const { data } = await api.get(`city/${cityId}/checkout`, {
            params: { cep }
        });
        const dates = data.data.dates;
        setGroupDeliveries(dates);
    }

    useEffect(() => {
        let toArrDates = [];
        Object.keys(groupDeliveries).map((delivery, key) => {
            toArrDates.push(delivery);
        });

        toArrDates.sort(function(a,b){
            return new Date(a) - new Date(b);
        });

        setArrDates(toArrDates);

    }, [groupDeliveries]);

    const handleOrder = () => {
        updateDate(date);
        updateHour(hour.hour);

        updateShowOrderDate(false);
        updateShowOrderName(true);

        updateCart(date, hour.hour);
    }

    const filteredMorning = value => value.hour < 3 && value.available_delivery > 0;
    const filteredEvening = value => value.hour >= 3 && value.available_delivery > 0;

    const handleDate = (d) => {
        let morning = groupDeliveries[d].filter(filteredMorning);
        let evening = groupDeliveries[d].filter(filteredEvening);

        setDate(d);
        setIsFind(true);

        setMorningHours(morning);
        setEveningHours(evening);
    }
    const handleHour = (h) => {
        setHour(h);
        setIsFind(false);
    }
    const formattedDate = (delivery) => {
        return new Date(`${delivery}T12:00:00Z`).toLocaleDateString() === new Date().toLocaleDateString();
    }
    return (
        <SideModalBase
            header={true}
            title='Pedido'
            actual='order_date'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="boxQuestionOrder">
                            <p>Escolha a melhor data para entrega</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="sliderDate">
                            <Slider {...settings}>
                                {arrDates.map((delivery, key) => (
                                    <div className={`boxDate ${delivery === date ? 'active' : ''}`} key={key} onClick={() => handleDate(delivery)}>
                                        <p>
                                            {formattedDate(delivery) &&
                                                "Hoje"
                                            }
                                            {!formattedDate(delivery) &&
                                                dayWeek[new Date(`${delivery}T12:00:00Z`).getDay()]
                                            }
                                        </p>
                                        <h4>{new Date(`${delivery}T12:00:00Z`).getDate()}</h4>
                                        <small>{month[new Date(`${delivery}T12:00:00Z`).getMonth()]}</small>
                                    </div>
                                ))}

                            </Slider>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={6}>
                        <h6 className="titleDate">Manhã</h6>
                        {morningHours.map((h, key) => (
                            <button className={`btnWhite ${h === hour ? 'active' : ''}`} key={key} onClick={() => handleHour(h)}>
                                {h.real_hour}
                            </button>
                        ))}

                    </Col>
                    <Col sm={6} xs={6}>
                        <h6 className="titleDate">Tarde</h6>
                        {eveningHours.map((h, key) => (
                            <button className={`btnWhite ${h === hour ? 'active' : ''}`} key={key} onClick={() => handleHour(h)}>
                                {h.real_hour}
                            </button>
                        ))}
                    </Col>
                </Row>
                <button className="btnOrange" onClick={handleOrder} disabled={isFind}>
                    Continuar
                    <img src={bigArrow} alt="Icon" />
                </button>
            </Container>
        </SideModalBase>
    );
}
export default OrderDate;