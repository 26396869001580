import moment from "moment";

export const getHours = () => {
    return [
        {init: moment('11:00', 'HH:mm'), final: moment('13:00', 'HH:mm')},
        {init: moment('13:00', 'HH:mm'), final: moment('14:30', 'HH:mm')},
        {init: moment('15:00', 'HH:mm'), final: moment('16:30', 'HH:mm')},
        {init: moment('17:00', 'HH:mm'), final: moment('18:30', 'HH:mm')},
        {init: moment('00:00', 'HH:mm'), final: moment('00:00', 'HH:mm')},
    ];

}

export const getHour = index => {
    if(!index){
        return '';
    }
    index = index -1;
    const hours = getHours();
    return `Das ${hours[index].init.format('HH:mm')} às ${hours[index].final.format('HH:mm')}`;
}

export const convertMoneyUsa = amount => {
    if(typeof amount === 'undefined'){
        return 0;
    }
    
    if (typeof amount === 'number') {
        return amount;
    }
    amount = amount.replace(/\./g, "");
    amount = amount.replace(",", ".");
    return parseFloat(amount);
}
export const convertMoneyBrazil = value => {
    value = value.toFixed(2).split('.');
    value[0] = value[0].split(/(?=(?:...)*$)/).join('.');
    return value.join(',');
}

export const getTotalWithShipping = (total, shipping, nItems) => {
    let totalShipping = convertMoneyUsa(shipping);
    let parcialShipping = totalShipping / nItems;
    let itemTotal = convertMoneyUsa(total);
    let itemTotalWithShipping = itemTotal + parcialShipping;
    return convertMoneyBrazil(itemTotalWithShipping);
}

export const convertDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
};

export const maskCreditCard = value => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{4})(\d)/g, "$1 $2");
    value = value.replace(/^(\d{4})\s(\d{4})3-3(\d)/g, "$1 $2 $3");
    value = value.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3 $4");
    return value.substr(0, 19);
}
export const maskExpiration = value => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d)/g, "$1/$2");
    return value.substr(0, 7);
}
export const maskCpf = value => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
export const maskSecurity = v => v.replace(/\D/g, "").substr(0, 3);

export const maskPhone = value => {
    let res = value.replace(/\D/g, '');
    if (res.length > 10) {
        return res
            .replace(/\D/g, '')
            .replace(/(\d{1})(\d)/, '($1$2')
            .replace(/(\d{2})(\d)/, '$1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
    } else {
        return res
            .replace(/\D/g, '')
            .replace(/(\d{1})(\d)/, '($1$2')
            .replace(/(\d{2})(\d)/, '$1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
    }
}

const addZero = (num) => {
    if (num <= 9)
        return "0" + num;
    return num;
}


export const cleanString = (str) => {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
    str = str.replace(/[àáâãäå]/g, "a");
    str = str.replace(/[ÈÉÊË]/g, "E");
    return str.replace(/[^a-z0-9]/gi, '');
}

export const getAvailablePeriods = value => {
    let hour = moment(value, 'HH:mm');
    const hours = getHours();
    let res = [];

    hours.map((h, index) => {
        if(hour.isBefore(h.init)) res.push(index + 1);
    });

    return res;
}