import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { storageGetItem, storageSetItem } from '../services/storage';

const HeaderContext = createContext({
    showBag: false,
    showBox: false,
    showOrder: true,
    showOrderCity: false,
    showOrderWithDrawal: false,
    showOrderCep: false,
    showOrderDate: false,
    showOrderName: false,
    showOrderGift: false,
    showSideModalBaseWidth: false,
    showOrderEnd: false,
    showCheckoutLogin: false,
    showCheckoutRegister: false,
    showCheckoutDelivery: false,
    showCheckoutPayment: false,
    showCheckoutEnd: false,
});
export const HeaderProvider = ({ children }) => {
    const [showBag, setShowBag] = useState(false);
    const [showBox, setShowBox] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [showOrderCity, setShowOrderCity] = useState(false);
    const [showOrderWithDrawal, setShowOrderWithDrawal] = useState(false);
    const [showOrderCep, setShowOrderCep] = useState(false);
    const [showOrderDate, setShowOrderDate] = useState(false);
    const [showOrderName, setShowOrderName] = useState(false);
    const [showOrderGift, setShowOrderGift] = useState(false);
    const [showSideModalBaseWidth, setShowSideModalBaseWidth] = useState(false);
    const [showOrderEnd, setShowOrderEnd] = useState(false);
    const [showCheckoutLogin, setShowCheckoutLogin] = useState(false);
    const [showCheckoutRegister, setShowCheckoutRegister] = useState(false);
    const [showCheckoutDelivery, setShowCheckoutDelivery] = useState(false);
    const [showCheckoutPayment, setShowCheckoutPayment] = useState(false);
    const [showCheckoutEnd, setShowCheckoutEnd] = useState(false);

    const [delivery, setDelivery] = useState('');
    const [cityData, setCityData] = useState({});
    const [city, setCity] = useState("");
    const [zipSet, setZipSet] = useState({});
    const [cep, setCep] = useState('');
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState("");
    const [name, setName] = useState(null);
    const [isGift, setIsGift] = useState(null);
    const [isGoing, setIsGoing] = useState(null);
    const [giftMessage, setGiftMessage] = useState('');
    const [total, setTotal] = useState(0);
    const [qtd, setQtd] = useState([]);
    const [cart] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);

    const updateShowBag = value => setShowBag(value);
    const updateShowBox = value => setShowBox(value);
    const updateShowOrder = value => setShowOrder(value);
    const updateShowOrderWithDrawal = value => setShowOrderWithDrawal(value);
    const updateShowOrderCity = value => setShowOrderCity(value);
    const updateShowOrderCep = value => setShowOrderCep(value);
    const updateShowOrderDate = value => setShowOrderDate(value);
    const updateShowOrderName = value => {
        setShowOrderName(value);
    };
    const updateShowOrderGift = value => setShowOrderGift(value);
    const updateShowSideModalBaseWidth = value => setShowSideModalBaseWidth(value);
    const updateShowOrderEnd = value => setShowOrderEnd(value);
    const updateShowCheckoutLogin = value => setShowCheckoutLogin(value);
    const updateShowCheckoutRegister = value => setShowCheckoutRegister(value);
    const updateShowCheckoutDelivery = value => setShowCheckoutDelivery(value);
    const updateShowCheckoutPayment = value => setShowCheckoutPayment(value);
    const updateShowCheckoutEnd = value => setShowCheckoutEnd(value);

    const updateDelivery = value => setDelivery(value);
    const updateCity = async (value) => {
        setCity(value);
        const response = await api.get(`city/${value}`);
        setCityData(response.data);
    };
    const updateZipSet = value => setZipSet(value);
    const updateCep = value => setCep(value);
    const updateDate = value => setDate(value);

    const updateHour = value => {
        setHour(value);
    };
    const updateName = value => setName(value);
    const updateIsGift = value => setIsGift(value);
    const updateIsGoing = value => setIsGoing(value);
    const updateGiftMessage = value => setGiftMessage(value);
    const updateTotal = value => {
        setTotal(value);
        storageSetItem('total', value);
    };
    const updateQtd = value => setQtd(value);
    const updateCart = (d = '', h = '', dW = '', pW = '') => {
        let cartJson = {
            delivery,
            city,
            zipSet,
            cep,
            date: d,
            hour: h,
            items: []
        }
        storageSetItem('cart', cartJson);
    };
    const updateItemCart = (value, item) => {
        let c = storageGetItem('cart');
        c.items[value] = item;
        storageSetItem('cart', c);
        setItemToEdit(null);
    }
    const addItemCart = () => {
        let c = storageGetItem('cart');
        let item = {
            name,
            isGift,
            isGoing,
            giftMessage,
            total,
            qtd,
        }
        c.items.push(item);
        storageSetItem('cart', c);
    }

    const deleteCart = (key) => {
        let c = storageGetItem('cart');
        let items = [];
        c.items.map((i, index) => {
            console.log(key, index);
            if(key !== index){
                items.push(i);
            }
        });
        c.items = items;
        storageSetItem('cart', c);
        updateName('');
        updateIsGift('none');
        updateIsGoing(false);
        updateGiftMessage('');
        setItemToEdit(null);

    }

    const updateItemToEdit = (value) => setItemToEdit(value);

    const updateKeyCart = (key, value) => {
        let c = storageGetItem('cart');
        if (c) {
            c[key] = value;
        }
        storageSetItem('cart', c);
    }

    const getHour = () => {
        let c = storageGetItem('cart');
        return c.hour;
    }

    const getDate = () => {
        let c = storageGetItem('cart');
        return c.date;
    }


    return (
        <HeaderContext.Provider
            value={{
                showBag,
                showBox,
                showOrder,
                showOrderCity,
                showOrderWithDrawal,
                showOrderCep,
                showOrderDate,
                showOrderName,
                showOrderGift,
                showSideModalBaseWidth,
                showOrderEnd,
                showCheckoutLogin,
                showCheckoutRegister,
                showCheckoutDelivery,
                showCheckoutPayment,
                showCheckoutEnd,
                delivery,
                city,
                cityData,
                zipSet,
                cep,
                date,
                hour,
                name,
                isGift,
                isGoing,
                total,
                qtd,
                cart,
                itemToEdit,
                giftMessage,
                getHour,
                getDate,
                updateShowBag,
                updateShowBox,
                updateShowOrder,
                updateShowOrderCity,
                updateShowOrderWithDrawal,
                updateShowOrderCep,
                updateShowOrderDate,
                updateShowOrderName,
                updateShowOrderGift,
                updateShowSideModalBaseWidth,
                updateShowOrderEnd,
                updateShowCheckoutLogin,
                updateShowCheckoutRegister,
                updateShowCheckoutDelivery,
                updateShowCheckoutPayment,
                updateShowCheckoutEnd,
                updateDelivery,
                updateCity,
                updateZipSet,
                updateCep,
                updateDate,
                updateHour,
                updateName,
                updateIsGift,
                updateIsGoing,
                updateTotal,
                updateQtd,
                updateCart,
                updateItemCart,
                addItemCart,
                updateItemToEdit,
                updateGiftMessage,
                updateKeyCart,
                deleteCart
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
}

export const useHeader = () => {
    const context = useContext(HeaderContext);
    return context;
}
export default HeaderContext;