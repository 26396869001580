import React, { useEffect, useState } from "react";

import './style.css';

import SideModalBaseCheckout from '../../components/SideModalBaseCheckout';
import api from "../../services/api";
import { storageGetItem, storageSetItem } from "../../services/storage";
import { getCustomer } from "../../services/auth";
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { convertDate, getHour, maskPhone } from "../../services/helpers";
import bigArrow from '../../images/big-arrow.png';
import { useHistory } from "react-router";
import { toast } from 'react-toastify';


const Delivery = () => {
    const [inPhone, setInPhone] = useState('');
    const [inPhoneWork, setInPhoneWork] = useState('');
    const [cart, setCart] = useState(null);
    const [periodWithdrawal, setPeriodWithdrawal] = useState('');
    const [dateWithdrawal, setDateWithdrawal] = useState('');
    const [delivery, setDelivery] = useState('');
    const [receiver, setReceiver] = useState('');
    const [cep, setCep] = useState('');
    const [zipSet, setZipSet] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [complement, setComplement] = useState('');
    const [comments, setComments] = useState('');
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [cities, setCities] = useState([]);
    const [citySelect, setCitySelect] = useState("");
    const [groupDeliveries, setGroupDeliveries] = useState({});
    const [hours, setHours] = useState([]);

    const history = useHistory();

    useEffect(() => {
        let body = document.querySelector("body");
        body.classList.add("overflow-h");

        const getCities = async () => {
            const { data } = await api.get('/city');
            setCities(data);
        }
        let c = storageGetItem('cart');
        if(c === null){
            window.location.href='/pedidos';
            return;
        }
        let customer = getCustomer();
        setReceiver(c.receiver ?? customer.name);

        if (c.cep) {
            getCep(c.cep, c.city);
        }
        getCities();

        // let cDate = new Date(c.date) < new Date() ? new Date() : new Date(c.date);
        let cDateFormatted = convertDate(c.date);
        setCitySelect(c.city);
        getCityData(c.cep, c.city, cDateFormatted);

        if (typeof c.city !== 'undefined') {
            setHour(c.hour.hour);
        }
        setDelivery(c.delivery);
        setDate(cDateFormatted);
        setCart(c);


        setPeriodWithdrawal(c.periodWithdrawal);
        setDateWithdrawal(c.dateWithdrawal);
        setCep(c.cep);
        setHour(c.hour);

    }, []);

    const getCityData = async (cep, cityId, date) => {
        const { data } = await api.get(`city/${cityId}/checkout`, {
            params: { cep }
        });
        const dates = data.data.dates;
        const hours = dates[date] ?? [];
        // if (hours.length === 0) {
        //     setDate("");
        // }

        setHours(hours);
        setGroupDeliveries(dates);
    }

    const getCep = async (cep, cityId = null) => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (cityId !== null) {
                const zipSetResponse = await api.get(`city/${cityId}/zip_set`, {
                    params: { cep: cep }
                });
                setZipSet(zipSetResponse.data.zip_set);
            }
            setStreet(response.data.logradouro);
            setState(response.data.uf);
            setDistrict(response.data.bairro);
            setCity(response.data.localidade);
        } catch (error) {
            console.log(error.response);
        }
    }

    const handleChange = (e) => {
        if (e.target.name === 'cep') {
            let value = e.target.value;
            let c = value.replace('_', '');
            if (c.length === 9) {
                getCep(e.target.value, citySelect);
                getCityData(c, citySelect, date);
            }
            setCep(e.target.value);
        }
        if (e.target.name === 'date') {
            setHours(groupDeliveries[e.target.value]);
            setDate(e.target.value);
        }
        if (e.target.name === 'dateWithdrawal') {
            setDateWithdrawal(e.target.value);
        }
        if (e.target.name === 'periodWithdrawal') {
            setPeriodWithdrawal(e.target.value);
        }
        if (e.target.name === 'hour') {
            setHour(e.target.value);
        }
        if (e.target.name === 'receiver') {
            setReceiver(e.target.value);
        }
        if (e.target.name === 'street') {
            setStreet(e.target.value);
        }
        if (e.target.name === 'number') {
            setNumber(e.target.value);
        }
        if (e.target.name === 'state') {
            setState(e.target.value);
        }
        if (e.target.name === 'city') {
            setCity(e.target.value);
        }
        if (e.target.name === 'district') {
            setDistrict(e.target.value);
        }
        if (e.target.name === 'complement') {
            setComplement(e.target.value);
        }
        if (e.target.name === 'comments') {
            setComments(e.target.value);
        }
        if (e.target.name === 'inPhone') {
            setInPhone( maskPhone(e.target.value) );
        }
        if (e.target.name === 'inPhoneWork') {
            setInPhoneWork( maskPhone(e.target.value) );
        }
    }

    const handleCity = (e) => {
        setCitySelect(e.target.value);

        if (cep.indexOf("_") === -1 && cep.length > 0) {
            getCityData(cep, e.target.value, date);
        }
    }

    const handleDate = (e) => {
        setDate(e.target.value);
        let hours = groupDeliveries[e.target.value].filter(filtered);
        function filtered(value) {
            return value.available_delivery > 0;
        }
        setHours(hours);
    }
    const handleHour = (e) => {
        setHour(e.target.value);
    }
    const handleDelivery = (typeDelivery) => {
        setDelivery(typeDelivery);
    }

    const validate = () => {
        if (inPhone.length < 14) {
            toast.error("Informe o telefone pessoal.");
            return false;
        }
        if (delivery === 'delivery') return validateIfDelivery();
        return validateIfWithdrawal();
    }
    const validateIfDelivery = () => {
        if (receiver === '') {
            toast.error("Informe quem irá retirar.");
            return false;
        }
        if (citySelect === '') {
            toast.error("Informe a sua cidade.");
            return false;
        }
        if (cep === '') {
            toast.error("Informe seu CEP antes de continuar");
            return false;
        }
        if (zipSet === null) {
            toast.error("O seu CEP não está disponível para entrega ");
            return false;
        }
        if (date === '') {
            toast.error("Informe a data para entrega.");
            return false;
        }
        if (hour === '') {
            toast.error("Informe o horário para entrega.");
            return false;
        }
        if (street === '') {
            toast.error("Informe a rua para entrega.");
            return false;
        }
        if (number === '') {
            toast.error("Informe o número do endereço.");
            return false;
        }
        if (district === '') {
            toast.error("Informe o o seu bairro.");
            return false;
        }
        if (state === '') {
            toast.error("Informe o estado.");
            return false;
        }
        if (city === '') {
            toast.error("Informe sua cidade");
            return false;
        }

        return true;
    }
    const validateIfWithdrawal = () => {
        if (dateWithdrawal === '') {
            toast.error("Informe a data que pretende retirar.");
            return false;
        }
        if (periodWithdrawal === '') {
            toast.error("Informe o período que pretende retirar.");
            return false;
        }
        if (receiver === '') {
            toast.error("Informe quem irá retirar.");
            return false;
        }
        return true;
    }
    const next = () => {

        const valid = validate();
        if (valid) {
            let c = cart;
            c.phone = inPhone;
            c.phoneWork = inPhoneWork;
            c.cep = cep;
            c.receiver = receiver;
            c.delivery = delivery;
            if (delivery === 'delivery') {
                // c.periodWithdrawal = "";
                c.city = citySelect;
                // c.dateWithdrawal = "";
                // c.date = date;
                c.zipSet = zipSet;
                c.address = {
                    street,
                    number,
                    district,
                    state,
                    city,
                    complement,
                };
                c.comments = comments;
            } else {
                c.address = {};
                c.comments = "";
                // c.date = "";
                // c.hour = "";
                c.zipSet = null;
                // c.periodWithdrawal = periodWithdrawal;
                // c.dateWithdrawal = dateWithdrawal;
            }
            setCart(c);
            storageSetItem('cart', c);
            history.push('/checkout/pagamento');
        }

    }

    return (
        <SideModalBaseCheckout
            header={true}
            title='Dados de entrega'
            actual='delivery_data'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        {/* <div className="questionOrder orderDelivery">
                            <button className={`btnCheckout ${delivery === 'delivery' ? 'active' : ''}`} onClick={() => handleDelivery('delivery')}>
                                Entrega
                            </button>
                            <button className={`btnCheckout ${delivery === 'withdrawal' ? 'active' : ''}`} onClick={() => handleDelivery('withdrawal')}>
                                Retirada
                            </button>
                        </div> */}
                        <Form>
                            <FormGroup>
                                <label htmlFor="inPhone">Telefone pessoal</label>
                                <Input type="text" name="inPhone" value={inPhone} onChange={handleChange} id="inPhone" placeholder="Ex: (11) 98080-8080" />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="inPhoneWork">Telefone do trabalho</label>
                                <Input type="text" name="inPhoneWork" value={inPhoneWork} onChange={handleChange} id="inPhoneWork" placeholder="Ex: (11) 3030-3030" />
                            </FormGroup>
                        </Form>
                        <Form id="formDelivery" style={{ display: `${delivery === 'delivery' ? 'block' : 'none'}` }}>
                            
                            
                            <Row>
                                <Col sm={12} xs={12}>
                                    <FormGroup>
                                        <label htmlFor="city_select">Cidade</label>
                                        <Input type="select"
                                            name="city_select"
                                            value={citySelect}
                                            onChange={handleCity}
                                            disabled={true}
                                        >
                                            <option disabled value="">Selecione...</option>
                                            {cities.map((city, key) => (
                                                <option key={key} value={city.id}>{city.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={6}>
                                    <FormGroup>
                                        <label htmlFor="receiver">Quem irá receber?</label>
                                        <Input type="text" name="receiver" value={receiver} onChange={handleChange} id="receiver" placeholder="Ex: Patrícia" />
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xs={6}>
                                    <FormGroup>
                                        <label htmlFor="cep">CEP</label>
                                        <InputMask mask="99999-999" value={cep} type="text" name="cep" id="cep" onChange={handleChange} placeholder="Ex: 08220-010">
                                            {(inputProps) => <Input {...inputProps} />}
                                        </InputMask>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={6}>
                                    <FormGroup>
                                        <label>Data</label>
                                        <Input type="text"
                                            value={date}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xs={6}>
                                    <FormGroup>
                                        <Label>Horário</Label>
                                        <Input type="text"
                                            value={getHour(hour)}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8} xs={8}>
                                    <FormGroup>
                                        <label htmlFor="street">Endereço</label>
                                        <Input type="text" name="street" id="street" value={street} onChange={handleChange} placeholder="Ex: Rua Patativa" />
                                    </FormGroup>
                                </Col>
                                <Col sm={4} xs={4}>
                                    <FormGroup>
                                        <label htmlFor="number">Número</label>
                                        <Input type="text" name="number" id="number" value={number} onChange={handleChange} placeholder="Ex: 26" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} >
                                    <FormGroup>
                                        <label htmlFor="district">Bairro</label>
                                        <Input type="text" name="district" id="district" value={district} onChange={handleChange} placeholder="Ex: Cidade Antônio Estêvão de Carvalho" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm={12} xs={12}>
                                    <FormGroup>
                                        <label htmlFor="complement">Complemento</label>
                                        <Input type="text" name="complement" id="complement" value={complement} onChange={handleChange} placeholder="Ex: Apartamento 125" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4} xs={4}>
                                    <FormGroup>
                                        <Label htmlFor="state">Estado</Label>
                                        <Input type="select" name="state" value={state} onChange={handleChange} id="state">
                                            <option disabled value="">Selecione..</option>
                                            <option value="AC">AC</option>
                                            <option value="AL">AL</option>
                                            <option value="AP">AP</option>
                                            <option value="AM">AM</option>
                                            <option value="BA">BA</option>
                                            <option value="CE">CE</option>
                                            <option value="DF">DF</option>
                                            <option value="ES">ES</option>
                                            <option value="GO">GO</option>
                                            <option value="MA">MA</option>
                                            <option value="MT">MT</option>
                                            <option value="MS">MS</option>
                                            <option value="MG">MG</option>
                                            <option value="PA">PA</option>
                                            <option value="PB">PB</option>
                                            <option value="PR">PR</option>
                                            <option value="PE">PE</option>
                                            <option value="PI">PI</option>
                                            <option value="RJ">RJ</option>
                                            <option value="RN">RN</option>
                                            <option value="RS">RS</option>
                                            <option value="RO">RO</option>
                                            <option value="RR">RR</option>
                                            <option value="SC">SC</option>
                                            <option value="SP">SP</option>
                                            <option value="SE">SE</option>
                                            <option value="TO">TO</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={8} xs={8}>
                                    <FormGroup>
                                        <label htmlFor="city">Cidade</label>
                                        <Input type="text" value={city} name="city" id="city" onChange={handleChange} placeholder="Ex: São Paulo" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup style={{ marginBottom: 150 }}>
                                        <Label htmlFor="comments">Observações</Label>
                                        <Input type="textarea" name="comments" value={comments} id="comments" onChange={handleChange} placeholder="Ex: Deixar na vizinha" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form >
                        <Form id="formWithdrawal" style={{ display: `${delivery === 'withdrawal' ? 'block' : 'none'}` }}>
                            <Row>
                                <Col sm={6} xs={6}>
                                    <FormGroup>
                                        <label>Data</label>
                                        <Input type="text"
                                            value={date}
                                            disabled={true}
                                        >
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xs={6}>
                                    <FormGroup>
                                        <Label htmlFor="periodWithdrawal">Período</Label>
                                        <Input type="text" value={getHour(hour)} disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <label htmlFor="receiver">Quem irá retirar?</label>
                                        <Input type="text" name="receiver" id="receiver" value={receiver} onChange={handleChange} placeholder="Ex: Patrícia" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col >
                </Row >
                <button className="btnOrange" onClick={next}>
                    Continuar
                    <img src={bigArrow} alt="Icon" />
                </button>
                {/* <button className="button-link" id="finishLater" >finalizar mais tarde</button> */}
            </Container >
        </SideModalBaseCheckout >
    );
}
export default Delivery;
